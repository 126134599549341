@use "../utils" as *;

/*----------------------------------------*/
/* 10. Common
/*----------------------------------------*/

// Social Icons

.social {
  &.one {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    transition: all 0.4s linear;

    a {
      width: 35px;
      height: 35px;
      background: linear-gradient(180deg, #243949 0%, #00151e 100%);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 16px;
        transition: all 0.1s linear;
      }
      &:hover {
        animation: pulse-small 0.8s;
        i {
          color: var(--theme-1);
        }
      }
    }
  }
}
