@use "../utils" as *;

.cogni_benefits_section {
  z-index: 9;
  position: relative;
  background-color: black;
  padding: 100px 0;
  padding-bottom: 150px;
  @media #{$sm, $xs, $xss} {
    padding: 40px 0 !important;
  }
  h2 {
    margin-bottom: 100px;
    text-align: center;
  }
}

.cogini_slider {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: end;
  align-items: center;
  .cogini_slider_arrow {
    display: flex;
    gap: 20px;
    i {
      font-size: 30px;
      cursor: pointer;
    }
    position: absolute;
    bottom: -50px;
    left: 63%;
    @media #{ $md, $sm, $xs, $xss} {
      position: absolute;
      top: -51px;
      left: auto;
      right: 25px;
    }
  }
  .sideimage {
    width: 657px;
    height: 500px;
    position: absolute;
    left: 30px;
    @media #{ $md, $sm, $xs, $xss} {
      display: none;
    }
  }
  .cogini_slider_main {
    display: flex;
    position: relative;
    width: 60%;
    @media #{ $md, $sm, $xs, $xss} {
      width: 100%;
    }

    .new {
      img {
        width: 70px;
        height: 70px;
        @media #{  $xss} {
          width: 50px;
          height: 50px;
        }
      }
      display: flex !important;
      flex-direction: column;
      align-items: start;
      height: 353px;
      width: 100px;
      background: #000c14;
      gap: 10px;
      padding: 30px;
      h6 {
        color: white;
        font-size: 20px;
        @media #{  $xss} {
          font-size: 18px;
        }
      }
    }
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  display: flex !important;
  align-items: center;
  gap: 20px !important;
}

.cogni_lesson {
  position: relative;
  padding: 100px 0;
  z-index: 9;
  background-color: #f8f9fa;
  @media #{$sm, $xs, $xss} {
    padding: 40px 0 !important;
  }
}

.cogni_lesson_container {
  h2 {
    color: black;
    text-align: center;
    span {
      color: var(--theme-1);
    }
    @media #{  $xs, $xss} {
      font-size: 26px;
    }
  }
  p {
    color: black;
    text-align: center;
    margin-bottom: 60px;
  }
}

.cogni_card_container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  justify-content: space-between;
  @media #{ $md, $sm, $xs, $xss} {
    gap: 30px;
    justify-content: center;
    row-gap: 60px;
  }
  .cogni_card {
    width: 320px;
    box-shadow: #63636333 0px 2px 8px 0px;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    background-color: white;
    @media (max-width: 500px) {
      width: 100%;
    }
    @media #{  $lg} {
      width: 300px;
    }

    div {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 0px;
      left: 50%;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 9px solid #f8f9fa;
      background: linear-gradient(141.91deg, #5AA9C3 15.05%, #004D66 82.85%);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        object-fit: contain;
        width: 40px;
      }
    }
    h6 {
      color: black;
      text-align: center;
      font-size: 20px;
    }
    p {
      margin: 0;
      text-align: center;
    }
  }
}
.award-part{
  @media #{$xs,$xss} {
padding: 0 !important;
  }
}

.cogni_responsive {
  @media #{$xs,$xss} {
    width: 100% !important;
  }
}
