.Deve_process_fulldiv {
    width: 100%;
    background: white !important;
}

.timeline {
    position: relative;
    max-width: 1104px;
    margin: 0 auto;
}

.container_devo {
    padding: 10px 50px;
    position: relative;
    width: 50%;
    border-radius: 5px;
    opacity: 0;
}

.container_devo.visible {
    animation: movedown 0.5s linear forwards;
}

.container_devo:nth-child(1).visible {
    animation-delay: 0s;
}

.container_devo:nth-child(2).visible {
    animation-delay: 0.5s;
}

.container_devo:nth-child(3).visible {
    animation-delay: 1s;
}

.container_devo:nth-child(4).visible {
    animation-delay: 1.5s;
}

.container_devo:nth-child(5).visible {
    animation-delay: 2s;
}

.container_devo:nth-child(6).visible {
    animation-delay: 2.5s;
}

.container_devo:nth-child(7).visible {
    animation-delay: 3s;
}

@keyframes movedown {
    0% {
        opacity: 1;
        transform: translateY(-30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.text-box {
    padding: 20px 30px;
    background: wheat;
    position: relative;
    border-radius: 5px;
    font-size: 15px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.text-box h2 {
    color: black;
    font-size: 18px !important;
}

.text-box p {
    color: black;
}

.left-container {
    left: 0;
}

.right-container {
    left: 50%;
}

.right-container .devo_round_div {
    position: absolute;
    width: 50px;
    height: 50px;
    background: white;
    border-radius: 50%;
    left: -25px;
    top: 32px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.left-container .devo_round_div {
    position: absolute;
    width: 50px;
    height: 50px;
    background: white;
    border-radius: 50%;
    right: -25px;
    top: 32px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


.container_devo img {
    width: 30px;
    height: 30px;
}

.right-container img {
    left: -20px;
}

.timeline::after {
    content: "";
    position: absolute;
    width: 6px;
    height: 100%;
    background: gray;
    top: 0;
    left: 50%;
    margin-left: -3px;
    z-index: -1;
    animation: moveline 1s linear forwards;
}

@keyframes moveline {
    0% {
        height: 0;
    }

    100% {
        height: 100%;
    }
}

.left-container-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #13C4A1;
    right: -15px;
}

.right-container-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #13C4A1;
    left: -15px;
}

@media screen and (max-width: 768px) {
    .timeline::after {
        left: 31px;
    }

    .container_devo {
        width: 100%;
        padding-left: 80px;
        padding-right: 25px;
    }

    .right-container {
        left: 0 !important;
    }

    .left-container img,
    .right-container img {
        left: 0px;
    }

    .left-container-arrow,
    .right-container-arrow {
        border-right: 15px solid #13C4A1;
        border-left: 0;
        left: -15px;
    }

    .left-container .devo_round_div {
        left: -5px;
        top: 25px;
    }

    .right-container .devo_round_div {
        left: -5px;
        top: 25px;
    }
}