/* metaverseservice */

.card_metaverse {
    position: relative;
    display: flex;
    justify-content: center;
    width: 320px;
    height: 430px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 32px;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.content_metaverse {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    color: #e8e8e8;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.content_metaverse .heading_metaverse {
    font-weight: 700;
    font-size: 24px;
    color: black;
    text-align: center;
    line-height: 30px;
}

@media screen and (max-width: 768px) {
    .content_metaverse .heading_metaverse {
        font-size: 20px;
    }
}

.content_metaverse .para_metaverse {
    line-height: 1.5;
    color: black;
    text-align: center;

}

.content_metaverse .btn_metaverse {
    color: #e8e8e8;
    text-decoration: none;
    padding: 10px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    background: linear-gradient(-45deg, #f89b29 0%, #ff0f7b 100%);
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.card_metaverse::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(146.38deg, #00E5DE 0%, #00807C 100%);
    z-index: -1;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.card_metaverse:hover::before {
    width: 100%;
}

.card_metaverse:hover {
    box-shadow: none;
}

.card_metaverse:hover .btn_metaverse {
    color: #212121;
    background: #e8e8e8;
}

.content_metaverse .btn_metaverse:hover {
    outline: 2px solid #e8e8e8;
    background: transparent;
    color: #e8e8e8;
}

.content_metaverse .btn_metaverse:active {
    box-shadow: none;
}

.card_metaverse:hover .content_metaverse .heading_metaverse,
.card_metaverse:hover .content_metaverse .para_metaverse {
    color: white;
}




@media screen and (max-width: 1000px) {
    .card_metaverse {
        width: 100%;
        height: 100%;
    }

}




/* block-chain card grident  */



.card_metaverse1 {
    position: relative;
    display: flex;
    justify-content: center;
    width: 320px;
    height: 430px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 32px;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.card_metaverse1::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(146.38deg, #4D97E5 0%, #002E60 100%);
    z-index: -1;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.card_metaverse1:hover::before {
    width: 100%;
}

.card_metaverse1:hover {
    box-shadow: none;
}

.card_metaverse1:hover .btn_metaverse {
    color: #212121;
    background: #e8e8e8;
}

.card_metaverse1:hover .content_metaverse .heading_metaverse,
.card_metaverse1:hover .content_metaverse .para_metaverse {
    color: white;
}






/* defi card grident  */



.card_metaverse2 {
    position: relative;
    display: flex;
    justify-content: center;
    width: 320px;
    height: 430px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 32px;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.card_metaverse2::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(146.38deg, #00E5DE 0%, #00807C 100%);
    z-index: -1;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.card_metaverse2:hover::before {
    width: 100%;
}

.card_metaverse2:hover {
    box-shadow: none;
}

.card_metaverse2:hover .btn_metaverse {
    color: #212121;
    background: #e8e8e8;
}

.card_metaverse2:hover .content_metaverse .heading_metaverse,
.card_metaverse2:hover .content_metaverse .para_metaverse {
    color: white;
}



/* AR card grident  */



.card_metaverse3 {
    position: relative;
    display: flex;
    justify-content: center;
    width: 320px;
    height: 430px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 32px;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.card_metaverse3::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(146.38deg, #4AA4DA 0%, #003454 100%);
    z-index: -1;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.card_metaverse3:hover::before {
    width: 100%;
    
}

.card_metaverse3:hover {
    box-shadow: none;
}

.card_metaverse3:hover .btn_metaverse {
    color: #212121;
    background: #e8e8e8;
}

.card_metaverse3:hover .content_metaverse .heading_metaverse,
.card_metaverse3:hover .content_metaverse .para_metaverse {
    color: white;
}




/* AR card grident  */



.card_metaverse4 {
    position: relative;
    display: flex;
    justify-content: center;
    width: 320px;
    height: 430px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 32px;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.card_metaverse4::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(146.38deg, #25E9EA 0%, #005B5B 100%);
    z-index: -1;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.card_metaverse4:hover::before {
    width: 100%;
}

.card_metaverse4:hover {
    box-shadow: none;
}

.card_metaverse4:hover .btn_metaverse {
    color: #212121;
    background: #e8e8e8;
}

.card_metaverse4:hover .content_metaverse .heading_metaverse,
.card_metaverse4:hover .content_metaverse .para_metaverse {
    color: white;
}





/* meta features */

.feature_div_metaverse {
    width: 100%;
    margin: 80px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 44px;
    padding: 0;
    justify-content: center;
}


.card_feature_metaverse {
    width: 100%;
    max-width: 220px;
    min-width: 200px;
    height: 200px;
    background-color: #ffffff;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 16px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}

.icon_feature_metaverse {
    margin: 0 auto;
    width: 100%;
    height: 80px;
    max-width: 80px;
    /* background: linear-gradient(90deg, #ffffff 0%, #ffffff 40%, rgb(0, 0, 0) 60%); */
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.8s ease;
    background-position: 0px;
    background-size: 200px;
}

.icon_feature_metaverse img {
    fill: white !important;
    width: 50px;
    height: 50px;
}

.card_feature_metaverse .title_feature_metaverse {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 10px;
    color: black;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px !important;
    font-family: var(--ff-p) !important;
}

.card_feature_metaverse:hover .icon_feature_metaverse {
    background-position: -120px;
    transition: all 0.3s ease;
}

.card:hover .icon_feature_metaverse svg path {
    fill: url('#gradientColor');
    transition: all 0.3s ease;
}

@media screen and (max-width:550px) {
    .card_feature_metaverse {
        width: 100%;
        max-width: 45%;
        min-width: 100px;
        height: 170px;
    }

    .feature_div_metaverse {
        width: 100%;
        margin: 30px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0;
        padding: 0;
        justify-content: center;
    }
}

@media screen and (max-width:550px) {
    .card_feature_metaverse {
        width: 100%;
        max-width: 45%;
        min-width: 100px;
        height: 170px;
    }

    .feature_div_metaverse {
        width: 100%;
        margin: 30px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0;
        padding: 0;
        justify-content: center;
    }
}

@media screen and (max-width:768px) {
    .card_metaverse3 {
        width: 100%;
    }
}


@media screen and (max-width:1201px) {
    .metaverse_hero_para {
        width: 100% !important;
    }
}

@media screen and (max-width:550px) {
    .card_feature_metaverse {
        width: 100%;
        max-width: 45%;
        min-width: 100px;
        height: 170px;
    }}