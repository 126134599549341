@use "../utils" as *;

.metaverseroyal_hero {
  position: relative;
  padding: 100px 0 60px 0;
  height: 90vh;
  background-image: url(../../img/metaverse-royale/dl.beatsnoop.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media #{ $sm,$xs,$xss } {
    height: 100%;
  }
  @media #{ $xss } {
    padding: 80px 0;
  }
  .metaverseroyal_hero_container {
    display: flex;
    align-items: center;
    height: 100%;
    @media #{ $sm,$xs,$xss } {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    .left {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      @media #{ $sm,$xs,$xss } {
        justify-content: center;
        align-items: center;
      }
      h2 {
        color: white;
        span {
          color: #efc060;
        }
        @media #{ $sm,$xs,$xss } {
          text-align: center;
        }
        @media #{$xs,$xss } {
          font-size: 26px;
        }
      }
      p {
        @media #{ $sm,$xs,$xss } {
          text-align: center;
        }
      }
    }
    .right {
      flex: 1;
      img {
        @media #{ $sm,$xs,$xss } {
          width: 100%;
        }
      }
    }
  }
}

.Royal_section2 {
  background-color: black;
  padding: 100px 0;
  h2 {
    text-align: center;
    margin-bottom: 40px;
    span {
      color: #efc060;
    }
    @media #{ $xs ,$xss } {
      font-size: 26px;
    }
  }
  .section2_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
    @media #{$sm, $xs ,$xss } {
      flex-direction: column;
      gap: 40px;
    }
    .right {
      width: 50%;
      @media #{$sm, $xs ,$xss } {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      p {
        @media #{$sm, $xs ,$xss } {
          text-align: center;
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @media #{$sm, $xs ,$xss } {
          justify-content: center;
        }
        li {
          display: flex;
          align-items: baseline;
          gap: 20px;
          font-size: 16px;
          i {
            color: #efc060;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.Royal_section3 {
  background-color: #24292d;
  padding: 100px 0;
  h2 {
    text-align: center;
    margin-bottom: 40px;
    span {
      color: #efc060;
    }
    @media #{ $xs ,$xss } {
      font-size: 26px;
    }
  }
  .section3_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
    @media #{$sm, $xs ,$xss } {
      flex-direction: column-reverse;
      gap: 40px;
    }
    .right {
      width: 50%;
      @media #{$sm, $xs ,$xss } {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      p {
        @media #{$sm, $xs ,$xss } {
          text-align: center;
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        li {
          display: flex;
          align-items: baseline;
          gap: 20px;
          font-size: 16px;
          i {
            color: #efc060;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.Royal_section4 {
  background-color: white;
  padding: 100px 0;
  h2 {
    text-align: center;
    color: black;
    span {
      color: #efc060;
    }
    @media #{ $xs ,$xss } {
      font-size: 26px;
    }
  }
  p {
    color: black;
    text-align: center;
    margin-bottom: 40px;
  }
  .section4_container {
    .card_section1 {
      display: flex;
      justify-content: space-between;
      gap: 40px;
      @media #{$sm, $xs ,$xss } {
        flex-direction: column;
        align-items: center;
      }
      .royal_cards {
        flex: 1;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        position: relative;
        border-radius: 8px;
        padding: 0 0 10px 0px;
        @media #{$sm, $xs ,$xss } {
          width: 94%;
        }

        img {
          width: 100%;
          object-fit: cover;
          height: 70px;
        }

        .royal_cards_side_img {
          background-color: white;
          position: absolute;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          top: -20px;
          left: -20px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          @media (max-width: 500px) {
            width: 77px;
            height: 77px;
            top: -3px;
            left: -23px;
          }
          img {
            width: 60px;
            height: 60px;
            @media (max-width: 500px) {
              width: 45px;
              height: 45px;
            }
          }
        }
        div {
          width: 100%;
          display: flex;
          align-items: end;
          flex-direction: column;
          h6 {
            margin-top: 5px;
            text-align: start;
            width: 83%;
            color: black;
            font-size: 18px;
          }
          p {
            text-align: start;
            width: 83%;
            margin: 0;
          }
        }
      }
    }
    .card_section2 {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      gap: 40px;
      @media #{$sm, $xs ,$xss } {
        flex-direction: column;
        align-items: center;
      }
      .royal_cards {
        flex: 1;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        position: relative;
        border-radius: 8px;
        padding: 0 0 10px 0px;
        @media #{$sm, $xs ,$xss } {
          width: 94%;
        }
        img {
          width: 100%;
          object-fit: cover;
          height: 70px;
        }

        .royal_cards_side_img {
          background-color: white;
          position: absolute;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          top: -20px;
          left: -20px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          @media (max-width: 500px) {
            width: 77px;
            height: 77px;
            top: -3px;
            left: -23px;
          }
          img {
            width: 60px;
            height: 60px;
            @media (max-width: 500px) {
              width: 45px;
              height: 45px;
            }
          }
        }
        div {
          width: 100%;
          display: flex;
          align-items: end;
          flex-direction: column;
          h6 {
            margin-top: 5px;
            text-align: start;
            width: 83%;
            color: black;
            font-size: 18px;
          }
          p {
            text-align: start;
            width: 83%;
            margin: 0;
          }
        }
      }
    }
  }
}

.Royal_section5 {
  background-color: black;
  padding: 100px 0;
  h2 {
    color: #efc060;
    text-align: center;
    @media #{ $xs ,$xss } {
      font-size: 26px;
    }
  }
  p {
    text-align: center;
    margin-bottom: 80px;
  }
  .section5_container {
    .card1 {
      display: flex;
      margin-bottom: 40px;
      gap: 30px;
      @media #{$sm, $xs ,$xss } {
        flex-direction: column;
        align-items: center;
      }
      .section5_card {
        flex: 1;
        gap: 20px;
        display: flex;
        align-items: start;
        @media #{$sm, } {
          width: 90%;
        }
        .div_img {
      padding: 10px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }

        .section5_card_content {
          display: flex;
          flex-direction: column;
          h6 {
            font-size: 18px;
          }
          p {
            text-align: start;
            margin: 0;
          }
        }
      }
    }
  }
}

.Royal_section6 {
  background-color: #24292d;
  padding: 80px 0;
  h2 {
    text-align: center;
    span {
      color: #efc060;
    }
  }
  p {
    text-align: center;
  }
}

.section6_card {
  padding: 13px 22px !important;
  gap: 10px !important;
}
.section7_card {
  padding: 10px !important;
}
.section_8_card {
  margin-top: 60px !important;
}

.Royal_section8 {
  background-color: black;
  padding: 100px 0;
  h2 {
    text-align: center;
    span {
      color: #efc060;
    }
  }
  p {
    color: white;
    text-align: center;
  }
}

.section8_container {
  display: none;
  @media (min-width: 768px) {
    display: flex;
    margin-top: 80px;
    justify-content: center;
    gap: 40px;
  }
  .section8_left {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: start;
    p {
      width: 100%;
      padding: 10px;
      text-align: start;
      cursor: pointer;
    }
  }
  .section8_card_container1 {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: start;
    align-items: center;
    @media #{ $md  } {
      gap: 20px;
    }
    .card8 {
      width: 45%;
      background-color:white;
      position: relative;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 8px;
      padding: 10px;
      @media #{ $md  } {
        width: 200px;
      }
      .bg {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 0;
        img {
          width: 60px;
          height: 60px;
        }
      }
      p {
        margin: 0;
        color: black;
      }
    }
  }
  h6 {
    margin: 40px 0 50px 0;
  }
}
.section8_responsive {
  margin-top: 60px;
  @media (min-width: 768px) {
    display: none;
  }
  .heading {
    color: black;
    margin: 60px 0;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  .section8_card_container1 {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    row-gap: 30px;
    justify-content: center;
    @media (max-width: 468px) {
      row-gap: 50px;
    }
    .card8 {
      width: 250px;
      background-color:white;
      position: relative;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 8px;
     
      padding: 10px;
      @media #{ $xs  } {
        width: 200px;
      }
      @media (max-width: 468px) {
        width: 100%;
      }
      .bg {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 0;
        img {
          width: 60px;
          height: 60px;
        }
      }
      p {
        color: black;
        margin: 0;
      }
    }
  }
}

.section8_card_border {
  background:#00ECE5;
  border-radius: 6px;
  color: white;
}

.metaverse-faq{
  @media #{ $md,$sm,$xs,$xss  } {
    margin: 0 !important;
  }
}