@use "../utils" as *;

.industry_wide_section {
  background-color: white;
  position: relative;
  z-index: 9;
  padding: 100px 0;
  @media #{$sm, $xs, $xss} {
    padding: 40px 0 !important;
  }
  h2 {
    color: var(--theme-1);
    text-align: center;
    margin-bottom: 50px;

    @media #{$md} {
      font-size: 32px;
    }

    @media #{$sm,$xs,$xss} {
      font-size: 26px;
    }
  }

  .industry_wide_section_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 30px;

    @media #{$lg,} {
      padding: 0 200px;
      justify-content: center;
      gap: 30px;
    }

    @media #{$md,} {
      padding: 0 100px;
      justify-content: center;
      gap: 30px;
    }

    @media #{$md,} {
      padding: 0 100px;
      justify-content: center;
      gap: 30px;
    }

    @media #{$sm,$xs,$xss} {
      justify-content: center;
      gap: 30px;
    }

    .industry_wide_card {
      position: relative;
      background-repeat: no-repeat;
      object-fit: cover;
      height: 339px;
      width: 250px;
      padding: 10px;
      padding-top: 220px;
      display: flex;
      z-index: 20;
      border-radius: 6px;
      align-items: start;
      overflow: hidden;
      justify-content: center;
      @media (max-width: 500px) {
        width: 100%;
      }
      .classy_simx_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 11;
      }
      img {
        object-fit: cover;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9;
        height: 100%;
        transition: ease-in-out 0.5s;
        &:hover {
          scale: 1.1;
        }
      }

      p {
        z-index: 20;
        font-size: 18px;
        text-align: center;
        margin-bottom: 35px;
      }
    }
  }
}

.key_features {
  padding: 100px 0;
  position: relative;
  z-index: 9;
  @media #{$sm, $xs, $xss} {
    padding: 40px 0 !important;
  }
  h2 {
    color: var(--theme-1);
    text-align: center;
    margin-bottom: 10px;

    @media #{$md} {
      font-size: 32px;
    }

    @media #{$sm,$xs,$xss} {
      font-size: 26px;
    }
  }

  p{
    margin-bottom: 40px;
    @media #{$sm, $xs, $xss} {
      margin-bottom: 30px !important;
    }
  }
  .key_features_section_container {
    display: flex;
    justify-content: space-between;
    row-gap: 21px;
    padding: 60px 30px 0 30px !important;
    @media #{$sm, $xs, $xss} {
      padding: 0 15px !important;
      margin-top:40px;
    }

    @media #{$lg,$md,$sm,$xs,$xss} {
      justify-content: center;
      gap: 28px;
    }

    .key_features_card {
      width: 349px;
      height: 349px;
      background-color: rgba(0, 12, 20, 1);
      padding: 45px 20px;
      display: flex;
      flex-direction: column;
      gap: 0px;
      @media #{$sm, $xs, $xss} {
        gap: 0px !important;
        padding: 30px 15px;
      }
      @media #{$xss} {
        padding: 30px 20px;
        gap: 15px;
      }
    
      img {
        width: 50px;
        height: 50px;
        margin-bottom: 30px;
      }

      h6 {
        font-size: 16px;
      }
      p{
        margin-bottom: 40px;
        @media #{$sm, $xs, $xss} {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.How_it_works {
  padding: 100px 0;
  position: relative;
  z-index: 9;
  @media #{$sm, $xs, $xss} {
    padding: 40px 0 !important;
  }

  h2 {
    margin-bottom: 50px;
    text-align: center;
    color: var(--theme-1);

    @media #{$sm,$xs,$xss} {
      font-size: 26px;
    }
  }

  .How_it_works_section_container {
    .How_it_works_main {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 30px;

      @media #{$sm,$xs,$xss} {
        display: none;
      }

      .How_it_works_left {
        width: 50%;
        display: flex;
        gap: 20px;
        flex-direction: column;
        height: 400px;

        .card_section1 {
          display: flex;
          width: 100%;
          height: 200px;
          justify-content: space-between;
          gap: 30px;

          .How_it_works_card {
            gap: 15px;
            width: 100%;
            height: 100%;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            pad: 10px;
            border-radius: 6px;
            transition: 0.5s all;

            img {
              width: 50px;
            }

            p {
              color: black;
              text-align: center;
            }

            &:hover p {
              color: white;
              font-weight: 500;
            }

            &:hover {
              background-color: #00151e;
            }
          }
        }

        .card_section2 {
          display: flex;
          width: 100%;
          justify-content: space-between;
          gap: 30px;
          height: 200px;

          .How_it_works_card {
            gap: 15px;
            width: 100%;
            height: 100%;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            pad: 10px;
            border-radius: 6px;

            img {
              width: 50px;
            }

            p {
              color: black;
              text-align: center;
              font-weight: 500;
            }

            &:hover {
              background-color: #00151e;
            }

            &:hover p {
              color: white;
            }
          }
        }
      }

      .How_it_works_right {
        width: 50%;
        height: 400px;
        position: relative;
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        @media #{$xss} {
          gap: 10px;
        }

        .bg_black {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.438);
          z-index: 10px;
        }

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        h6 {
          color: white;
          position: relative;
          z-index: 11;
          text-align: center;

          @media #{$xss} {
            font-size: 18px;
          }
        }

        p {
          color: white;
          position: relative;
          z-index: 11;
          text-align: center;
        }
      }
    }

    .How_it_works_main_responsive {
      display: flex;
      flex-direction: column;
      gap: 30px;

      @media (min-width: 769px) {
        display: none;
      }

      .How_it_works_right {
        width: 100%;
        height: 320px;
        position: relative;
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        .bg_black {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.438);
          z-index: 10px;
        }

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        h6 {
          color: white;
          position: relative;
          z-index: 11;
          text-align: center;
        }

        p {
          color: white;
          position: relative;
          z-index: 11;
          text-align: center;
        }
      }
    }
  }
}

.RaidensimX_benefit {
  position: relative;
  z-index: 9;
  padding: 100px 0;
  @media #{$sm, $xs, $xss} {
    padding: 40px 0 !important;
  }
  h2 {
    color: var(--theme-1);
    text-align: center;
    margin-bottom: 50px;
  }

  .RaidensimX_benefit_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media #{$md,$sm,$xs,$xss} {
      justify-content: center;
      row-gap: 30px;
      gap: 30px;
    }

    @media #{$md} {
      padding: 0 90px;
    }

    .RaidensimX_benefit_card {
      border-radius: 8px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 250px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      @media #{$lg} {
        width: 220px;
      }

      @media #{$xs,$xss} {
        width: 100%;
      }

      img {
        border-radius: 8px 8px 0 0;
        height: 190px;
        width: 100%;
        object-fit: cover;
      }

      h6 {
        color: black;
        margin: 0;
        font-size: 16px;
      }

      p {
        color: black;
        line-height: 20px;
      }
    }
  }
}

.RaidensimX_commitment {
  padding: 100px 0;
  @media #{$sm, $xs, $xss} {
    padding: 40px 0 !important;
  }

  h2 {
    color: var(--theme-1);
    text-align: center;
    margin-bottom: 10px;

    @media #{$xss} {
      font-size: 26px;
    }
  }

  position: relative;
  z-index: 9;

  .RaidensimX_commitment_container {
    h5 {
      margin-bottom: 50px;
      font-size: 18px;
      font-weight: 400;
      color: black;
      text-align: center;
      font-family: "Nunito Sans", sans-serif;
    }

    .RaidensimX_commitment_boxs {

      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;

      @media #{$lg } {
        gap: 10px;

      }

      @media #{$md,$sm} {
        gap: 20px;
        justify-content: center;
        padding: 0 100px;
      }

      @media #{$xs,$xss} {
        gap: 0px;
        flex-direction: column;
        align-items: center;
      }

      .RaidensimX_commitment_box {
        width: 242px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        gap: 25px;

        @media #{$lg } {
          width: 220px;
          padding: 10px;

        }

        img {
          width: 50px;
        }

        p {
          text-align: center;
          color: black;
        }
      }

      @media #{$md,$sm,$xs,$xss} {
        gap: 0px;
      }

      @media #{$xs,$xss} {
        gap: 0px;
        flex-direction: column;
        align-items: center;
      }


    }
  }
}