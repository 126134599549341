@use "../utils" as *;

.contact_popup {
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(8, 8, 8, 0.747);
  display: flex;
  justify-content: center;
  align-items: center;

  .contact_popup_container {
    position: relative;
    background-color: white;
    width: 646px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    @media #{ $sm,$xs,$xss } {
      height: auto;
      margin-top: 0px;
      width: 90%;
    }
    @media #{ $xss } {
      padding: 20px 25px;
    }
    img {
      position: absolute;
      top: -199px;
      left: 50%;
      transform: translateX(-50%);
      height: 220px;
      @media #{ $sm,$xs,$xss } {
        display: none;
      }
    }
  }

  .contact_popup_container .contact_popup_close {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    margin-bottom: 34px;
    @media #{$sm, $xs, $xss} {
      margin-bottom: 10px;
    }

  }

  .contact_popup_container .contact_popup_close {
    div {
      h2 {
        font-size: 28px;
        margin: 0;
        color: rgba(5, 90, 155, 1);
        text-align: center;

        @media #{ $xss } {
          font-size: 18px;
        }
      }
      p {
        font-size: 14px;
        color: black;
        text-align: center;
      }
    }
  }

  .contact_popup_container .contact_popup_close i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    color: black;
    transition: 0.1s;
    @media #{$sm,$xs,$xss } {
      right: -3px;
      top: 18px;
      font-size: 20px;
    }
    @media #{ $xss } {
      right: -17px;
    
    }
  }

  .contact_inputs {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .first_input_container {
      display: flex;
      width: 100%;
      gap: 30px;
      @media #{ $xs,$xss } {
        flex-direction: column;
        gap: 25px;
      }
      div {
        position: relative;
        display: flex;
        width: 100%;

        input {
          width: 100%;
          border: none;
          border-bottom: 1px solid rgba(79, 79, 79, 1);
          text-decoration: none;
        }
        p {
          position: absolute;
          color: rgb(255, 107, 107);
          bottom: -25px;
          margin: 0;
          font-size: 14px;
        }
      }
    }
    .second_input_container {
      width: 100%;
      display: flex;
      gap: 30px;
      @media #{ $xs,$xss } {
        flex-direction: column;
        gap: 25px;
      }
      div {
        position: relative;
        display: flex;
        width: 100%;
        input {
          width: 100%;
          border: none;
          border-bottom: 1px solid rgba(79, 79, 79, 1);
          text-decoration: none;
        }
        p {
          position: absolute;
          color: rgb(255, 107, 107);
          bottom: -25px;
          margin: 0;
          font-size: 14px;


        }
      }
    }
    .third_input_container {
      width: 100%;
      display: flex;
   div{
    position: relative;
    display: flex;
    width: 100%;
    textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid rgba(79, 79, 79, 1);
      text-decoration: none;
      outline: none;
      height: 30px;
    }
    p {
      position: absolute;
      color: rgb(255, 107, 107);
      bottom: -25px;
      margin: 0;
      font-size: 14px;

    }
   }
    }
    button {
      height: 36px;
      width: 101px;
      border-radius: 8px;
      width: fit-content;
      background-color: rgba(5, 90, 155, 1);
      display: flex;
      align-items: center;
    }
  }
}

@keyframes identifier {
  to {
    transform: scale(1);
    opacity: 1;
  }
}
