@media screen and (max-width: 1000px) {

    .wrapper-inner {
        height: 410px !important;
    }

}

@media screen and (max-width: 600px) {

    .about_4_images {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {

    .wrapper-inner {
        height: 100% !important;
    }

}

.Hero_sub_heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 35px;
}

.game_hero_images {
    display: flex !important;
    justify-content: end !important;
}


/* career  */

.careersection_card_div {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


@media screen and (max-width: 1000px) {
    .game_hero_images img {
        width: 400px !important;
        height: 400px !important;
    }

    .product_what_we_do {
        max-width: 100% !important;
        padding: auto 30px !important;
    }

    .widget-project {
        margin-top: 30px !important;
    }

    .service_img_game {
        width: 60%;
        margin: 0 auto !important;
    }


}

@media screen and (max-width: 450px) {

    .product_detail_f_Card_co {
        width: 100%;
        padding: 0 15px;
    }

    .flip-card {
        width: 100% !important;
    }

    .left img {
        width: 35px !important;
    }

}

@media screen and (max-width: 768px) {

    .iconremovemobile {
        display: none;
    }

    .careersection_card_div {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .Industry h2 {
        margin-bottom: 0;
    }


    .Industry {
        transform: translateY(0px) !important;
    }

    .section-title-shape-one {
        margin-bottom: 20px !important;
    }

    .right-wrapper {
        margin-top: 10px !important;
    }

    .game_hero_images {
        display: none !important;
    }

    .game_hero_images img {
        width: 300px !important;
        height: 300px !important;
    }

    .about_para {
        width: 100% !important;
    }

    .vr-br-tag {
        display: flex !important;
    }

    .How_it_works_main_responsive {
        padding: 0;
    }

    .product_what_we_do {
        padding: auto 15px !important;
    }

    .about_game_image {
        margin-top: -70px !important;
    }

    .pagebenifit_container p {
        margin-bottom: 0px !important;
    }

    .service_img_game {
        width: 60% !important;
        margin: 0 auto !important;
    }

    .mobile_gamewhy_choose .mobile_game_right {
        gap: 20px !important;
    }

    .service_img_game_faq {
        display: none !important;
    }

    .game_hero_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .Hero_sub_heading {
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 25px !important;
    }

    .metaverseroyal_hero {
        background-image: none !important;
    }

    .metaverseroyal_hero_unity,
    .metaverseroyal_hero_unreal,
    .metaverseroyal_hero_mmorpg,
    .metaverseroyal_hero_p2e,
    .metaverseroyal_hero_web3 {
        background-image: none !important;
    }

}

/* key_features_card_white - raide-simx */

.key_features_card_white {
    background: white !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.key_features_card_white h6 {
    color: black !important;
}

.key_features_card_white p {
    color: black !important;
}


/* li-cogni  */

.li-cogni {
    display: flex !important;
    flex-direction: column;
    color: black !important;
    font-size: 16px;
}

.li-cogni li {
    list-style-type: square !important;
    color: black;
}


@media screen and (max-width:1085px) {
    .has-dropdown {
        margin-right: 15px !important;
    }
}

/* // banner */

.metaverseroyal_hero {
    position: relative;
    padding: 100px 0 60px 0;
    height: 90vh;
    background-image: url(`../../../public/assets/img/metaverse-royale/dl.beatsnoop.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #061D2F;
}

.metaverseroyal_hero_unity {
    position: relative;
    padding: 100px 0 60px 0;
    height: 90vh;
    background-image: url(`../../../public/assets/img/unity/hero.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    background-color: #96394D;
}

.metaverseroyal_hero_unreal {
    position: relative;
    padding: 100px 0 60px 0;
    height: 90vh;
    background-image: url(`../../../public/assets/img/unreal/hero.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    background-color: #3F4F5E;
}

.metaverseroyal_hero_mmorpg {
    position: relative;
    padding: 100px 0 60px 0;
    height: 90vh;
    background-image: url(`../../../public/assets/img/mmorpg-img/hero.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    background-color: #435F6C;
}

.metaverseroyal_hero_p2e {
    position: relative;
    padding: 100px 0 60px 0;
    height: 90vh;
    background-image: url(`../../../public/assets/img/p2e/her.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    background-color: #26415E;
}

.metaverseroyal_hero_web3 {
    position: relative;
    padding: 100px 0 60px 0;
    height: 90vh;
    background-image: url(`../../../public/assets/img/web3game-img/hero.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    background-color: #190739;
}

.web3game_banner {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex !important;
    align-items: center !important;
}

.p2e_banner {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex !important;
    align-items: center !important;
}

.casino_banner {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex !important;
    align-items: center !important;
}

/* br tag  */

@media screen and (max-width: 768px) {
    br {
        display: none !important;
    }

    .our_mission_about_para {
        width: 90% !important;
    }

    .about_section_img_1 {
        position: absolute;
        width: 50px !important;
        left: 0 !important;
        bottom: 0;
    }

    .about_section_img_2 {
        position: absolute;
        width: 50px !important;
        right: 0 !important;
    }
}


@media screen and (max-width: 1000px) {
    .banner_right_image {
        display: none !important;
    }
}


/* contact */

@media screen and (max-width:992px) {
    .contact_box_card {
        margin-bottom: 20px !important;
    }
}


/* ai service */

.ai_service_li li {
    color: black !important;
    list-style-type: disc;
    margin-left: 18px;
    font-size: 16px;
    margin-bottom: 10px;
}

.ai_process_li {
    list-style-type: disc;
    margin-left: 18px;
}


/* about section image */

.about_section_img_1 {
    position: absolute;
    width: 80px;
    left: 8%;
    bottom: 0;
}

.about_section_img_2 {
    position: absolute;
    width: 80px;
    right: 10%;
}

.about_what_we_do_image_1 img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 2px;
}

.about_what_we_do_image_2 img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    margin-top: 15px;
    border-radius: 2px;
}

.about_why_card_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 40px;
}

.about_why_card {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about_why_card img {
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
    margin-bottom: 15px;
}

.about_why_card p {
    text-align: center;
}

.about_what_we_do_2_div {
    display: flex;
    align-items: center;
    gap: 150px;
}

.about_what_we_do_2_div_2 {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 150px;
    margin-top: 70px;
}

.about_what_we_do_2_image_1 img {

    width: 150px !important;
    height: 150px !important;
}

.about_what_we_do_2_image_2 img {

    width: 150px !important;
    height: 150px !important;
}

.about_what_we_do_2_image_1 img {
    position: relative;
    left: 60px;
}

.about_what_we_do_2_image_2 img {
    position: relative;
    right: 60px;
}

.whychoose_left_about_image_div {
    display: flex;
    gap: 20px;
}

.whychoose_left_about_image1 {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.whychoose_left_about_image2 {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}


.whychoose_left_about_image1 img {
    width: 100%;
    height: 230px;
    margin-top: 20px;
    object-fit: cover;
}

.whychoose_left_about_image2 img {
    width: 100%;
    height: 230px;
    margin-bottom: 20px;
    object-fit: cover;
}

.whychoose_left_about_content h6 {
    color: black;
    font-size: 20px;
}

.whychoose_left_about_content p {
    color: black;
}

.whychoose_left_about_image_div2 {
    display: flex;
    width: 48%;
}

.whychoose2_left_about_content h6 {
    color: black;
    font-size: 20px;
}

.whychoose2_left_about_content p {
    color: black;
}


.about_image_round_div_why {
    width: 100px !important;
    height: 100px !important;
    background: black;
    border-radius: 50%;
}



/* fotter section  */

.footer_contact_div {
    display: flex;
    justify-content: space-between;
    padding: 70px 40px;
    border: 1px solid #424242;
    position: relative;
    flex-wrap: wrap;
}

.footer_contact_div_heading {
    position: absolute;
    padding: 20px;
    font-size: 30px;
    background: #000C14;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.footer_icon {
    padding-right: 10px;
    color: #13C4A1;
    font-size: 16px;
}

.footer_one_div {
    padding: 0 20px;
}

.footer_link {
    color: #13C4A1;

}

.india_fla_div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.india_flag {
    width: 25px;
    object-fit: cover;
    /* border-radius: 50%; */
}

.footer_mail_phone {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    position: relative;
    bottom: -50px;
}

.footer_award {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    gap: 20px;
}

.footer_award img {
    width: 120px;
}


@media screen and (max-width: 1000px) {

    .footer_contact_div {
        padding: 60px 10px;
    }

}

@media screen and (max-width: 710px) {
    .footer_award {
        display: flex;
        margin-top: 20px;
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;
    }

    .footer-bottom {
        margin-top: 20px !important;
    }

    .footer_award img {
        width: 100px;
    }
}

@media screen and (max-width: 600px) {

    .footer_mail_phone {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        position: relative;
        bottom: 0px;
    }

    .footer_award {
        gap: 50px;
    }


    .footer_contact_div {
        padding: 20px 10px;
    }

    .footer_contact_div_heading {
        font-size: 22px !important;
    }

    .india_fla_div {
        font-size: 18px !important;
    }

    .footer-section.one {
        padding: 40px 0 100px 0 !important;
    }

}


.contact_right_image {
    height: 450px;
    object-fit: cover;
}


@media screen and (max-width: 992px) {
    .contact_right_image {
        display: none !important;
    }

}

@media screen and (max-width: 500px) {

    .footer_award {
        gap: 30px;
    }

}

@media screen and (max-width: 400px) {

    .footer_award {
        gap: 30px;
    }

    .footer_award img {
        width: 80px;
    }

}


.fill_twitter:hover {
    fill: white !important;
}




/* loader css  */


.cookies-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    padding: 20px;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    border: 1px solid rgba(0, 0, 0, 0.35);
    color: white;
    opacity: 0;
    transition: opacity 1s ease;
}

.cookies-consent.show {
    opacity: 1;
}

.cookies-consent p {
    margin-bottom: 10px;
}

.cookies-consent .inner-btn {
    display: flex;
    justify-content: center;
}

.cookies-consent .default-btn {
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
}