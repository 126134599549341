.whychoose_right_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
    gap: 40px;
    width: 92%;
    align-items: center;
    margin: 60px auto auto auto;
    padding: 0;
}

@media screen and (max-width: 768px) {
    .whychoose_right_div {
        margin: 30px auto auto auto !important;
    }
}

.whychoose_fulldiv {
    padding: 100px 0 !important;
    background: #f8f9fa !important;
}

.whychoose_right_div>div>p {
    width: 450px;
    margin-bottom: 30px;
    color: black;
}

.ai_whychoose_card_div {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    width: 300px;
    color: black;
    background: rgba(246, 246, 246, 1);
    height: 111px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    position: relative;
    padding-left: 60px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.whychoose_ai_box {
    position: absolute;
    width: 64px;
    height: 66px;
    left: -10%;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.whychoose_ai_box>img {
    width: 40px;
    height: 40px;
}


@media screen and (max-width: 1100px) {
    .ai_whychoose_card_div {
        width: 45%;
        height: 111px;
    }
}


@media screen and (max-width: 800px) {
    .ai_whychoose_card_div {
        width: 100%;
        height: 111px;
        margin: 0 auto;
        padding-left: 70px;

    }

    .whychoose_ai_box {
        width: 64px;
        height: 66px;
        left: -3%;
    }
}

@media screen and (max-width: 550px) {
    .ai_whychoose_card_div {
        width: 100% !important;
        height: 111px;
        margin: 0 auto;
    }
}