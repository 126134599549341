.nft_timeline {
    position: relative;
    min-height: 150px;
    margin-top: 130px;
}

.nft_line {
    position: absolute;
    z-index: 2;
    left: calc(50% - 1px);
    width: 2px;
    top: -50px;
    background-color: #fff;
    display: none;
}

.nft_line:before,
.nft_line::after {
    position: absolute;
    display: block;
    content: '';
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%);
}

.nft_line_white:before,
.nft_line_white::after {
    background-color: black !important;
}


.nft_line:after {
    bottom: 0 !important;
}

.nft_section {
    display: flex;
    opacity: 0;
    transform: translateX(-100%);
    transition: 600ms ease;
    position: relative;
    z-index: 1;
    margin: 10px 0;
    padding: 1rem;
    align-items: center;
    min-height: 220px;
}

.nft_section:nth-child(odd) {
    flex-direction: row-reverse;
    transform: translateX(100%);
}

.bead {
    position: absolute;
    display: block;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #fff;
    left: 50%;
    top: 15%;
    transform: translateX(-50%);
}

.nft_content {
    width: 31%;
    margin-left: 120px;
}

.nft_content:nth-child(2) {
    margin-right: 120px !important;
}

.nft_content:nth-child(4) {
    margin-right: 120px !important;
}

.nft_content:nth-child(6) {
    margin-right: 120px !important;
}

.show-me:nth-child(n) {
    transform: none;
    opacity: 1;
    position: relative;
    z-index: 5;
}

.show-me .bead {
    background-color: white;
    height: 60px;
    width: 60px;
    color: black;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bead>img {
    width: 40px;
    height: 40px;

}

@media screen and (max-width: 1100px) {

    .nft_content {
        width: 40%;
        margin-left: 0px;
    }

    .nft_content:nth-child(2) {
        margin-right: 0px !important;
    }

    .nft_content:nth-child(4) {
        margin-right: 0px !important;
    }

    .nft_content:nth-child(6) {
        margin-right: 0px !important;
    }
}


@media screen and (max-width: 768px) {
    .nft_line {
        left: 67px;
    }

    .nft_section:nth-child(odd) {
        flex-direction: row;
    }

    .nft_section {
        display: flex;
        transform: translateX(100%);
        z-index: 1;
        margin: 10px 0;
        padding: 1rem;
        min-height: 180x;
        left: 100px;
    }

    .show-me .bead {
        background-color: white;
        width: 50px;
        height: 50px;
        color: black;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        left: -44px;
    }

    .bead>img {
        width: 30px;
        height: 30px;
    }

    .nft_content {
        width: calc(80%);
    }

}




@media screen and (max-width: 600px) {
    .nft_line {
        left: 30px;
    }

    .show-me .bead {
        left: -31px;
    }

    .nft_section {
        display: flex;
        transform: translateX(100%);
        z-index: 1;
        margin: 10px 0;
        padding: 1rem;
        min-height: 180x;
        left: 50px;
    }

    .nft_content {
        width: calc(80%);
    }

}


/* hero banner nft */

.hero-banner_div {
    position: relative !important;
    transform: translate(-50%, -50%) !important;
    top: 50% !important;
    left: 50% !important;
    margin: 0 !important;
}