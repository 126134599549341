.left_Language_div {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 61px;
    margin-bottom: 45px;
    justify-content: center;
}

.left_Language_div>div {
    font-family: var(--ff-heading);
    font-size: 22px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.right_Language_div {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@keyframes card-slide-in {
    from {
        opacity: 0;
        transform: translateY(150px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.card {
    width: 150px;
    height: 130px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    opacity: 0;
    background: #1C292E !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: none;
    gap: 10px;
    border-radius: 5px !important;
}

.card {
    animation: card-slide-in 0.5s ease-out forwards;
}

.card:nth-child(1) {
    animation-delay: 0.1s;
}

.card:nth-child(2) {
    animation-delay: 0.2s;
}

.card:nth-child(3) {
    animation-delay: 0.3s;
}

.card:nth-child(4) {
    animation-delay: 0.4s;
}

.card:nth-child(5) {
    animation-delay: 0.5s;
}

.card:nth-child(6) {
    animation-delay: 0.6s;
}

.card:nth-child(7) {
    animation-delay: 0.7s;
}

.card:nth-child(8) {
    animation-delay: 0.8s;
}

.card::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card:hover::before {
    opacity: 0;
}

.card img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}

.Card_name {
    color: white;
}

.Card_name_sevice {
    color: white;
}

/* card-2  */



@keyframes card-slide-in-2 {
    from {
        opacity: 0;
        transform: translateY(150px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


.card2 {
    width: 150px;
    height: 130px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    opacity: 0;
    background: #1C292E !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: none;
    gap: 10px;
    border-radius: 5px !important;
}

.card2 {
    animation: card-slide-in-2 0.5s ease-out forwards;
}

.card2:nth-child(1) {
    animation-delay: 0.1s;
}

.card2:nth-child(2) {
    animation-delay: 0.2s;
}

.card2:nth-child(3) {
    animation-delay: 0.3s;
}

.card2:nth-child(4) {
    animation-delay: 0.4s;
}

.card2:nth-child(5) {
    animation-delay: 0.5s;
}

.card2:nth-child(6) {
    animation-delay: 0.6s;
}

.card2:nth-child(7) {
    animation-delay: 0.7s;
}

.card2:nth-child(8) {
    animation-delay: 0.8s;
}

.card2::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card2:hover::before {
    opacity: 0;
}

.card2 img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}


/* card3 */

@keyframes card-slide-in-3 {
    from {
        opacity: 0;
        transform: translateY(150px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.card3 {
    width: 150px;
    height: 130px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    opacity: 0;
    background: #1C292E !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: none;
    gap: 10px;
    border-radius: 5px !important;
}

.card3 {
    animation: card-slide-in-3 0.5s ease-out forwards;
}

.card3:nth-child(1) {
    animation-delay: 0.1s;
}

.card3:nth-child(2) {
    animation-delay: 0.2s;
}

.card3:nth-child(3) {
    animation-delay: 0.3s;
}

.card3:nth-child(4) {
    animation-delay: 0.4s;
}

.card3:nth-child(5) {
    animation-delay: 0.5s;
}

.card3:nth-child(6) {
    animation-delay: 0.6s;
}

.card3:nth-child(7) {
    animation-delay: 0.7s;
}

.card3:nth-child(8) {
    animation-delay: 0.8s;
}

.card3::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card3:hover::before {
    opacity: 0;
}

.card3 img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}


/* card-4 */

@keyframes card-slide-in-4 {
    from {
        opacity: 0;
        transform: translateY(150px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.card4 {
    width: 150px;
    height: 130px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    opacity: 0;
    background: #1C292E !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: none;
    gap: 10px;
    border-radius: 5px !important;
}

.card4 {
    animation: card-slide-in-4 0.5s ease-out forwards;
}

.card4:nth-child(1) {
    animation-delay: 0.1s;
}

.card4:nth-child(2) {
    animation-delay: 0.2s;
}

.card4:nth-child(3) {
    animation-delay: 0.3s;
}

.card4:nth-child(4) {
    animation-delay: 0.4s;
}

.card4:nth-child(5) {
    animation-delay: 0.5s;
}

.card4:nth-child(6) {
    animation-delay: 0.6s;
}

.card4:nth-child(7) {
    animation-delay: 0.7s;
}

.card4:nth-child(8) {
    animation-delay: 0.8s;
}

.card4::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card4:hover::before {
    opacity: 0;
}

.card4 img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}

/* card5 */

@keyframes card-slide-in-5 {
    from {
        opacity: 0;
        transform: translateY(150px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.card5 {
    width: 150px;
    height: 130px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    opacity: 0;
    background: #1C292E !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: none;
    gap: 10px;
    border-radius: 5px !important;
}

.card5 {
    animation: card-slide-in-5 0.5s ease-out forwards;
}

.card5:nth-child(1) {
    animation-delay: 0.1s;
}

.card5:nth-child(2) {
    animation-delay: 0.2s;
}

.card5:nth-child(3) {
    animation-delay: 0.3s;
}

.card5:nth-child(4) {
    animation-delay: 0.4s;
}

.card5:nth-child(5) {
    animation-delay: 0.5s;
}

.card5:nth-child(6) {
    animation-delay: 0.6s;
}

.card5:nth-child(7) {
    animation-delay: 0.7s;
}

.card5:nth-child(8) {
    animation-delay: 0.8s;
}

.card5::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card5:hover::before {
    opacity: 0;
}

.card5 img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}


/* card6  */

@keyframes card-slide-in-6 {
    from {
        opacity: 0;
        transform: translateY(150px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.card6 {
    width: 150px;
    height: 130px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    opacity: 0;
    background: #1C292E !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: none;
    gap: 10px;
    border-radius: 5px !important;
}

.card6 {
    animation: card-slide-in-6 0.5s ease-out forwards;
}

.card6:nth-child(1) {
    animation-delay: 0.1s;
}

.card6:nth-child(2) {
    animation-delay: 0.2s;
}

.card6:nth-child(3) {
    animation-delay: 0.3s;
}

.card6:nth-child(4) {
    animation-delay: 0.4s;
}

.card6:nth-child(5) {
    animation-delay: 0.5s;
}

.card6:nth-child(6) {
    animation-delay: 0.6s;
}

.card6:nth-child(7) {
    animation-delay: 0.7s;
}

.card6:nth-child(8) {
    animation-delay: 0.8s;
}

.card6:nth-child(9) {
    animation-delay: 0.9s;
}

.card6::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card6:hover::before {
    opacity: 0;
}

.card6 img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}


@media screen and (max-width:1000px) {
    .right_Language_div {
        width: 100%;
        gap: 20px;
        margin-bottom: 20px;
    }

    .left_Language_div {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
    }

    .card,
    .card2,
    .card3,
    .card4,
    .card5,
    .card6 {
        width: 150px;
        height: 130px;
    }
}

@media screen and (max-width:770px) {

    .right_Language_div {
        margin-top: 30px;
        width: 100%;
        gap: 20px;
        justify-content: center;
    }

    .left_Language_div {
        justify-content: center;
    }

}




/* nft programming */

.Nft_slider {
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.Nft_slide-track {
    display: flex;
    width: calc(250px * 18);
    gap: 20px;
    animation: scroll 40s linear infinite;
    margin-top: 40px;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translatex(calc(-250px * 9));
    }
}

.Nft_slide {
    display: flex;
    align-items: center;
    width: 220px;
    height: 70px;
    gap: 10px;
    border-radius: 5px;
    transition: transform 1s;
    cursor: pointer;
    perspective: 100px;
}

.Nft_slide:hover {
    transform: translateZ(20px);
}


.Nft_slide img {
    width: 50px;
    height: 50px;
}


.Nft_slider::before {
    left: 0;
    top: 0;
}

.Nft_slide::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.Nft_program_name {
    margin-top: 40px;
    font-size: 24px;
}