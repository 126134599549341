@use "../utils" as *;
/* css slider */
.slider {
  height: 100vh;
  position: relative;
  overflow: hidden;
  @media #{$sm, $xs, $xss} {
    min-height: max-content;
  }
}
.slider .list .item {
  position: absolute;
  inset: 0 0 0 0;
  display: flex;
  overflow: hidden;
  opacity: 0;
  transition: 0.5s;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media #{$sm, $xs, $xss} {
    background-position: left;
    background-image: none !important;
  }
}


.slider .list .item .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 10%;
  z-index: 1;
  gap: 10px;

  @media #{$sm, $xs, $xss} {
    align-items: center;
  }
}
.slider .list .item .content p:nth-child(1) {
  text-transform: uppercase;
  letter-spacing: 10px;
}
.slider .list .item .content h2 {
  font-size: 40px;
 
  @media #{$sm, $xs, $xss} {
    text-align: center;
    width: 100%;
  }
}
.slider .list .item .content p {
  font-size: 20px;
  font-weight: 600;
  line-height: 35px;
  @media #{$sm, $xs, $xss} {
    text-align: center;
    width: 100%;
  }
  @media #{ $xs, $xss} {
    font-size: 18px;
  }
}
.slider .list .item .content a {
  @media #{$sm, $xs, $xss} {
    text-align: center;
  }
}
.slider .list .item.active {
  opacity: 1;
  z-index: 10;


}
@keyframes showContent {
  to {
    transform: translateY(0);
    filter: blur(0);
    opacity: 1;
  }
}
.slider .list .item.active p:nth-child(1),
.slider .list .item.active h2,
.slider .list .item.active p,
.slider .list .item.active a {
  transform: translateY(30px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent 0.6s 0.5s ease-in-out 1 forwards;
}
.slider .list .item.active h2 {
  animation-delay: 0.6s;
}
.slider .list .item.active p:nth-child(3) {
  animation-duration: 1s;
}
.arrows {
  position: absolute;
  top: 30%;
  right: 50px;
  z-index: 100;
}
.slider .list .item.active {
  width: 150px;
  height: 220px;
  position: absolute;
  border-radius: 30px;
  top: 100%;
  animation: showImage 0.5s linear 1 forwards;
}

@keyframes showImage {
  0% {
    bottom: 100%;
    left:50%;
    border-radius: 30px;
  }
  100% {
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
.arrows button {
  background-color: #eee5;
  border: none;
  font-family: monospace;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  font-size: x-large;
  color: #eee;
  transition: 0.5s;
}
.arrows button:hover {
  background-color: #eee;
  color: black;
}
.thumbnail {
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  right: 8%;
  z-index: 11;
  display: flex;
  gap: 10px;
  height: max-content;
  padding: 0 50px;
  box-sizing: border-box;
  overflow: auto;
  justify-content: end;
  align-items: flex-end;
  @media #{$sm, $xs, $xss} {
    right: 4%;
    padding: 0;
  }
  @media (max-width: 450px) {
    bottom: 70px;
  }
}
.thumbnail::-webkit-scrollbar {
  width: 0;
}
.thumbnail .item {
  width: 90px;

  filter: brightness(0.3);
  transition: 0.5s;
  flex-shrink: 0;
  @media (max-width: 450px) {
    width: 60px;
    height:max-content;
  }
}
.thumbnail .item img {
  width: 100%;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
  @media (max-width: 450px) {
    height: 40px;
  
  }
  
}
.thumbnail .item.active {
  filter: brightness(1.5);
}
.thumbnail .item .content {
  position: absolute;
  inset: auto 10px 10px 10px;
}

.underline{
  width: 100%;
  height: 4px;
  border-radius: 1px;
  background-color: white;
 margin-top: 10px;
}