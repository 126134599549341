.menupar-right {
    width: 100% !important;
    margin: 40px 20px !important;
}

/* Define styles for the menu content divs */
.menupar-right-team,
.menupar-right-project {
    display: none;
    /* Initially hide the content divs */
}

/* Show the content div when its corresponding menu item is hovered over */
.has-dropdown:hover .menupar-right-team {
    display: block;
}

.has-dropdown:hover .menupar-right-project {
    display: block;
}

.sub-menu-div {
    width: 20%;
    display: flex;
    flex-direction: column;
    background: #C3C3C3 !important;
    border-right: 5px solid #c3c3c3;
}

.menubar_inside_main_div {
    width: 100% !important;
}

.left_content_arrow {
    display: flex !important;
    justify-content: space-between;
    align-items: center;

}

.manubar_content_main_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: normal;
    column-gap: 60px;
}

.menubar_inside_main_heading {
    font-size: 26px;
    color: black;
    font-weight: 700;
    margin-bottom: 8px;
    font-family: var(--ff-heading);
}

/* .menubar_inside_main_paragraph {
    font-size: 16px;
    margin-bottom: 5px;
} */

.manubar_content_name {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}

.manubar_content_para {
    font-size: 16px;
    font-weight: 400;
    width: 240px;
}

.sub_heading_color {
    color: #13C4A1 !important;
}

/* 
.box {
    height: 5px;
    width: 20px;
    background: gray;
    position: relative;
    overflow: hidden;
}

.box::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: blue;
    transition: left 0.5s ease;

}

.box:hover::before {
    left: 0;
} */

#particles {
    width: 100%;
    height: 100%;
    position: absolute;
}

.serviceallmargin {
    margin-bottom: 25px;
}

@media screen and (max-width: 1477px) {
    .manubar_content_main_div {
        column-gap: 40px;
    }
}

@media screen and (max-width: 1405px) {
    .manubar_content_main_div {
        column-gap: 20px;
    }
}