@use "../utils" as *;

.UseCase {
  padding: 100px 0;
  position: relative;
  z-index: 9;
  background-color: white;
  @media #{$sm, $xs, $xss} {
    padding: 40px 0 !important;
  }

}

.UseCase_content {
  display: flex;
  flex-direction: column;
  gap: 50px;
  h2 {
    text-align: center;
    margin: 0;
    font-size: 35px;
    color: var(--theme-1);
  }
}

.UseCase_content_row {
  margin-top: 80px;
  @media #{$sm, $xs, $xss} {
    margin-top: 40px !important;
  }
  div {
    .right-wrapper {
      .title {
        font-size: 26px !important;
        @media #{ $md, $sm, $xs, $xss} {
          margin: 20px 0;
        }
      }
      p {
        color: black;
      }
    }
  }
}

.left-wrappers {
  div {
    img {
      width: 100%;
    }
  }
}

.UseCase_content_row {
  margin-top: 60px;
  div {
    h2 {
      text-align: start;
      margin-bottom: 10px;
    }
    h6 {
      color: var(--theme-1);
      font-size: 20px;
    }
    .small_card_container {
      margin-top: 45px;
      width: 100%;
      height: 100%;
      display: flex;
      gap: 10px;

      .cards {
        width: 100%;
        border-radius: 8px;
        height: 90px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 35px;
        .services_icon {
          top: 0px;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
          }
        }
        p {
          font-size: 14px;
          margin: 0;
          margin-bottom: 16px;
          text-align: center;
          line-height: 20px;
          color: black;
        }
      }
    }
    ul {
      li {
        font-size: 16px;
      }
    }
  }
}

.multi_player {
  background-color: #00151e;
  position: relative;
  z-index: 9;
  .multi_player_container {
    padding: 100px 0;
    h2 {
      text-align: center;
    }
  }
}
