.nft_whychoose_full_div {
    color: black;
}

.nft_whychoose_left_div {
    width: 100%;
}

.nft_whychoose_Right_div {
    margin-top: 100px !important;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* gap: 62px; */
    row-gap: 134px;
    padding: 0;
}

.nft_whychoose_Right_box {
    width: 256px;
    height: 111px;
    background: rgba(246, 246, 246, 1);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 16px;
}

.nft_whychoose_img {
    width: 40px;
    height: 40px;
}

.nft_img_round {
    width: 64px;
    height: 64px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    position: absolute;
    left: 17px;
    top: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
}

.nft_whychoose_name {
    font-size: 16px;
    margin-top: 15px;
    text-align: center !important;
}

@media screen and (max-width:1180px) {
    .nft_whychoose_Right_div {
        margin-top: 50px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 60px;
        row-gap: 100px;

    }
}

@media screen and (max-width:930px) {

    .nft_whychoose_Right_box {
        width: 48%;
        height: 111px;
    }

    .nft_whychoose_Right_div {
        margin-top: 50px;
        width: 93.5%;
        justify-content: space-between;
        gap: 0;
        row-gap: 80px;

    }

}

@media screen and (max-width:768px) {


    .nft_whychoose_Right_div {
        margin-top: 50px !important;
    }

}


@media screen and (max-width:500px) {

    .nft_whychoose_Right_box {
        width: 100%;
        height: 111px;
    }
}


@media screen and (max-width:500px) {
    .nft_whychoose_name {
        font-size: 14px;
        margin-top: 15px;
        text-align: center !important;
    }

    .nft_img_round {
        width: 55px;
        height: 55px;
        left: 17px;
        top: -30px;
    }

    .nft_whychoose_Right_div {
        row-gap: 50px;
        margin-top: 0;
    }
}


/* nft features */

@media screen and (max-width:500px) {
    .feature_div {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
        padding: 0;
        justify-content: center;
    }

    .feature_card {
        width: 45%;
        gap: 20px;
    }

    .idustry>div {
        color: white;
        font-size: 20px;
    }
}