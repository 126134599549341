.enqury_main_div {
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
}

.right_div {
    width: 87%;
    height: 625px;
    background: white;
    border-radius: 30px;
    padding: 30px 30px 30px 200px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.right_div p {
    color: black;
    text-align: center;
}

.left_div {
    position: absolute;
    width: 304px;
    height: 537px;
    object-fit: contain !important;
    background-position: center;
    border-radius: 30px;
    left: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.left_background_div {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    border-radius: 30px;
}

.left_background_div_content {
    font-size: 20px;
    font-weight: 600;
    padding: 30px 10px;
    text-align: center;
}


.right_div_contact_heading {
    color: black;
    text-align: center;
}

.right_div_input_div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    row-gap: 30px;
    margin-top: 60px;
}

.right_div_input_div div {
    width: 48%;
    position: relative;
}

.right_div_input_div div input {
    width: 100%;
    border: none;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
}

.form-control {
    width: 100% !important;
    border: none !important;
    outline: none !important;
    border-radius: 0 !important;
    position: relative !important;
    padding-bottom: 0 !important;
    top: 9px !important;
}

.phone_no_input {
    width: 100% !important;
    border-bottom: 1px solid black !important;
    padding-bottom: 15px !important
}

.flag-dropdown {
    background: transparent !important;
    border: none !important;
    width: 50px !important;
}

.country-name {
    color: black;
}

.form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none !important;
    ;
}

.right_div_input_div textarea {
    width: 100%;
    height: 80px;
    border: none;
    padding-bottom: 10px;
    outline: none;
    border-bottom: 1px solid black;
}

/* .right_div_input_div button {
    width: 193px;
    height: 51px;
    border-radius: 30px;
    background: black;
    color: white;
} */

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
    border-bottom: 1px solid black !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
    width: 100 !important;
    background: transparent !important;
    color: black !important;
    border-radius: 0 !important;
    border: none !important;
    font-size: 16px !important;
    font-family: var(--ff-p) !important;
}

.ReactFlagsSelect-module_label__27pw9 {
    color: black !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
    width: 100% !important;
}

.error-message {
    /* position: relative; */
    z-index: 2;
    color: red !important;
    font-size: 12px;
    position: absolute;
}

.text-contat-div {
    width: 100% !important;
}


/* button */

.Contact2_button {
    width: fit-content;
    font-family: inherit;
    font-size: 20px;
    background: #13C4A1;
    color: white;
    padding: 0.7em 1em;
    padding-left: 0.9em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.2s;
    cursor: pointer;
}

.Contact2_button span {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
}











@keyframes fly-1 {
    from {
        transform: translateY(0.1em);
    }

    to {
        transform: translateY(-0.1em);
    }
}


@media screen and (max-width:1000px) {
    .left_div {
        display: none;
    }

    .right_div {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        padding: 80px 40px 80px 40px;
    }
}


@media screen and (max-width:670px) {
    .right_div_input_div div {
        width: 100%;
    }



    .ReactFlagsSelect-module_flagsSelect__2pfa2 {
        width: 100% !important;
        border-bottom: 1px solid black !important;
    }

    .right_div_contact_heading {
        font-size: 22px;
    }

    .right_div p {
        font-size: 14px;
    }

    .right_div {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        padding: 60px 30px 60px 30px;
    }

    .Contact2_button {
        font-size: 16px;
    }

}



/* about section one */

.service-one_image {
    width: 60px;
}

.contact_error_message{
    position: absolute;

}