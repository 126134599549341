/* This is an example, feel free to delete this code */
.tooltip-container {
    background: rgb(3, 169, 244);
    background: linear-gradient(138deg,
            rgba(3, 169, 244, 1) 15%,
            rgba(63, 180, 233, 1) 65%);
    position: fixed;
    top: 50%;
    left: 50px;
    transform: translate(-50%, -50%);
    z-index: 999;
    cursor: pointer;
    font-size: 17px;
    padding: 25px;
    border-radius: 50px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
    .tooltip-container {
        display: none;
    }
}


.tooltip-container::after,
.tooltip-container::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #03a9f4;
    border-radius: 50%;
    z-index: -1;
    opacity: 0.7;
    top: 0;
    left: 0;
}

.tooltip-container::before {
    animation: pulse 2s ease-out infinite;
}

.tooltip-container::after {
    animation: pulse-social-icons 2s 1s ease-out infinite;
}

@keyframes pulse-social-icons {
    100% {
        transform: scale(1.8);
        opacity: 0;
    }
}

.tooltip-container:hover {
    background: #fff;
    transition: all 0.6s;
}

.tooltip-container .text {
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #fff;
    transition: all 0.2s;
}

.tooltip-container:hover .text {
    fill: rgb(3, 169, 244);
    transition: all 0.6s;
}

/* Inicio do tooltip twitter */
.tooltip1 {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    background: #fff;
    fill: #03a9f4;
    padding: 12px;
    border-radius: 50px;
    transition: opacity 0.5s, visibility 200s, top 0.3s, background 0.3s;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip-container:hover .tooltip1 {
    top: 130%;
    opacity: 1;
    visibility: visible;
    background: #fff;
    border-radius: 50px;
    transform: translate(-50%, -5px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip-container:hover .tooltip1:hover {
    background: #03a9f4;
    fill: #fff;
}

/* Fim do tooltip twitter */

/* Inicio do tooltip facebook */
.tooltip2 {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    background: #fff;
    fill: #A21CAF;
    padding: 12px;
    border-radius: 50px;
    transition: opacity 0.3s, visibility 0.3s, top 0.3s, background 0.3s;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip-container:hover .tooltip2 {
    top: -80%;
    opacity: 1;
    visibility: visible;
    background: #fff;
    transform: translate(-50%, -5px);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip-container:hover .tooltip2:hover {
    background: linear-gradient(45deg,
            #405de6,
            #5b51db,
            #b33ab4,
            #c135b4,
            #e1306c,
            #fd1f1f);
    fill: #fff;
}

/* Fim do tooltip facebook */

/* Inicio do tooltip whatsApp */
.tooltip3 {
    position: absolute;
    top: 100%;
    left: 60%;
    transform: translateX(80%);
    opacity: 0;
    visibility: hidden;
    background: #fff;
    fill: #1db954;
    padding: 12px;
    border-radius: 50px;
    transition: opacity 0.3s, visibility 0.3s, top 0.3s, background 0.3s;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip-container:hover .tooltip3 {
    top: 22%;
    opacity: 1;
    visibility: visible;
    background: #fff;
    transform: translate(110%, -5px);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip-container:hover .tooltip3:hover {
    background: #1db954;
    fill: #fff;
}

/* Fim do tooltip whatsApp */

/* Inicio do tooltip Discord */
.tooltip4 {
    position: absolute;
    top: 100%;
    left: -190%;
    transform: translateX(70%);
    opacity: 0;
    visibility: hidden;
    background: #fff;
    fill: #8c9eff;
    padding: 12px;
    border-radius: 50px;
    transition: opacity 0.3s, visibility 0.3s, top 0.3s, background 0.3s;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip-container:hover .tooltip4 {
    top: 10%;
    opacity: 1;
    visibility: visible;
    background: #fff;
    transform: translate(70%, -5px);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip-container:hover .tooltip4:hover {
    background: #8c9eff;
    fill: #fff;
}

/* Fim do tooltip Discord */

/* Inicio do tooltip pinterest */
.tooltip5 {
    position: absolute;
    top: 100%;
    left: -145%;
    transform: translateX(70%);
    opacity: 0;
    visibility: hidden;
    background: #fff;
    fill: #bd081c;
    padding: 12px;
    border-radius: 50px;
    transition: opacity 0.3s, visibility 0.3s, top 0.3s, background 0.3s;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip-container:hover .tooltip5 {
    top: -78%;
    opacity: 1;
    visibility: visible;
    background: #fff;
    transform: translate(70%, -5px);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip-container:hover .tooltip5:hover {
    background: #bd081c;
    fill: #fff;
}

/* Fim do tooltip pinterest */

/* Inicio do tooltip dribbble */
.tooltip6 {
    position: absolute;
    top: 100%;
    left: 35%;
    transform: translateX(70%);
    opacity: 0;
    visibility: hidden;
    background: #fff;
    fill: black;
    padding: 12px;
    border-radius: 50px;
    transition: opacity 0.3s, visibility 0.3s, top 0.3s, background 0.3s;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip-container:hover .tooltip6 {
    top: -50%;
    opacity: 1;
    visibility: visible;
    background: #fff;
    transform: translate(90%, -5px);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip-container:hover .tooltip6:hover {
    background: black;
    fill: #fff;
}

/* Fim do tooltip dribbble */

/* Inicio do tooltip github */
.tooltip7 {
    position: absolute;
    top: 100%;
    left: 39%;
    transform: translateX(70%);
    opacity: 0;
    visibility: hidden;
    background: #fff;
    fill: rgb(3, 169, 244);
    padding: 12px;
    border-radius: 50px;
    transition: opacity 0.3s, visibility 0.3s, top 0.3s, background 0.3s;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip-container:hover .tooltip7 {
    top: 95%;
    opacity: 1;
    visibility: visible;
    background: #fff;
    transform: translate(85%, -5px);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip-container:hover .tooltip7:hover {
    background: rgb(3, 169, 244);
    background: linear-gradient(138deg,
            rgba(3, 169, 244, 1) 15%,
            #3fb4e9 65%);
    fill: #fff;
}

/* Fim do tooltip github */

/* Inicio do tooltip reddit */
.tooltip8 {
    position: absolute;
    top: 100%;
    left: -150%;
    transform: translateX(70%);
    opacity: 0;
    visibility: hidden;
    background: #fff;
    fill: #ff4500;
    padding: 12px;
    border-radius: 50px;
    transition: opacity 0.3s, visibility 0.3s, top 0.3s, background 0.3s;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip-container:hover .tooltip8 {
    top: 101%;
    opacity: 1;
    visibility: visible;
    background: #fff;
    transform: translate(70%, -5px);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip-container:hover .tooltip8:hover {
    background: #ff4500;
    fill: #fff;
}

/* Fim do tooltip reddit */

/* Inicio do tooltip fixo */
.tooltip9 {
    position: absolute;
    top: -35px;
    left: -12%;
    opacity: 0;
    visibility: hidden;
    width: 150px;
    height: 250px;
    z-index: -1;
}

.tooltip-container:hover .tooltip9 {
    top: -110%;
    opacity: 1;
    visibility: visible;
    border-radius: 50%;
    z-index: -1;
}