.container-casino {
    width: 1200px !important;
    padding: 0 !important;
    margin-right: auto;
    margin-left: auto;
}

@media screen and (min-width: 992px) and (max-width: 1439px) {
    .container-casino {
        max-width: 1279px !important;
        padding: 0 !important;
        margin: 0 80px !important;
        width: auto !important;
    }
}

@media screen and (max-width: 991px) {
    .container-casino {
        max-width: 959px !important;
        margin: 0 16px !important;
        padding: 0 !important;
        width: auto !important;
    }
}

.gradient-cards-casino {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
}

@media screen and (max-width: 991px) {
    .gradient-cards-casino {
        grid-template-columns: 1fr;
    }
}

.container-title-casino {
    text-align: center;
    padding: 0 !important;
    margin-bottom: 40px;
    font-size: 40px;
    color: #fff;
    font-weight: 600;
    line-height: 60px;
}

.card-casino {
    max-width: 550px;
    border: 0;
    width: 100%;
    margin-inline: auto;
}

.container-card-casino {
    position: relative;
    border: 2px solid transparent;
    /* background: linear-gradient(71deg, #080509, #1a171c, #080509); */
    background: #0C1F01;
    background-clip: padding-box;
    border-radius: 45px;
    padding: 40px;
}

.container-card-casino img {
    margin-bottom: 32px;
}

.bg-green-box-casino,
.bg-white-box-casino,
.bg-yellow-box-casino,
.bg-blue-box-casino {
    position: relative;
}

.bg-green-box-casino::after,
.bg-white-box-casino::after,
.bg-yellow-box-casino::after,
.bg-blue-box-casino::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    content: "";
    z-index: -1;
    border-radius: 45px;
}

.bg-green-box-casino::after {
    background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
}

.bg-white-box-casino::after {
    background: linear-gradient(71deg, #121013, #b0afb0, #121013);
}

.bg-yellow-box-casino::after {
    background: linear-gradient(71deg, #110e0e, #afa220, #110e0e);
}

.bg-blue-box-casino::after {
    background: linear-gradient(71deg, #0c0a0e, #5f6fad, #0c0a0e);
}

.card-title-casino {
    font-weight: 600;
    color: white;
    letter-spacing: -0.02em;
    line-height: 40px;
    font-style: normal;
    font-size: 28px;
    padding-bottom: 8px;
    text-align: center;
}

.card-description-casino {
    font-weight: 600;
    line-height: 32px;
    /* color: rgba(255, 255, 255, 0.5); */
    color: white;
    font-size: 16px;
    max-width: 470px;
}


/* cards  */

.line-title {
    position: relative;
    width: 400px;
}

.line-title::before,
.line-title::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    border-radius: 2px;
}

.line-title::before {
    width: 100%;
    background: #f2f2f2;
}

.line-title::after {
    width: 32px;
    background: #e73700;
}

/* cards-coice */

.gradient-cards-casino-choice {
    display: flex;
}

.container-card-casino_choice {
    width: 100%;
    height: 400px;
    position: relative;
    border: 2px solid transparent;
    /* background: linear-gradient(71deg, #080509, #1a171c, #080509); */
    background: "white";
    background-clip: padding-box;
    border-radius: 8px;
    padding: 12px;
}

.container-card-casino_choice img {
    width: 100%;
    height: 179px;
    object-fit: cover;
}


.card-casino-choice {
    border: 0;
    margin-inline: auto;
}


.bg-green-box-casino-choice,
.bg-white-box-casino-choice,
.bg-yellow-box-casino-choice,
.bg-blue-box-casino-choice {
    position: relative;
}

.bg-green-box-casino-choice::after,
.bg-white-box-casino-choice::after,
.bg-yellow-box-casino-choice::after,
.bg-blue-box-casino-choice::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    content: "";
    z-index: -1;
    border-radius: 8px;
}

.bg-green-box-casino-choice::after {
    background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
}

.bg-white-box-casino-choice::after {
    background: linear-gradient(71deg, #121013, #b0afb0, #121013);
}

.bg-yellow-box-casino-choice::after {
    background: linear-gradient(71deg, #110e0e, #afa220, #110e0e);
}

.bg-blue-box-casino-choice::after {
    background: linear-gradient(71deg, #0c0a0e, #5f6fad, #0c0a0e);
}

.choice_slider_arrow {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-bottom: 20px;
    margin-left: -10px !important;
}

.choice_slider_arrow i {
    font-size: 30px;
    cursor: pointer;
    color: black;

}

.choice_slider_arrow i:hover {
    color: #13C4A1;
}

/* Choice of Games: */
.choicegame_main_div {
    background: #24292D;
}

.choicegame_main_div h2 {
    margin-bottom: 10px;
}

.choicegame_main_p {
    text-align: center;
    width: 60%;
    margin: 0 auto;
}

.coice-link-div {
    display: flex;
    gap: 40px;
    margin-top: 50px;
    margin-bottom: 150px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.Choice_active_div {
    font-size: 20px;
    cursor: pointer;
}

.Choice_active {
    color: #57E400;
    border-bottom: 5px solid #57E400;
    border-radius: 5px;
    padding-bottom: 10px;
    font-size: 20px;
    cursor: pointer;
}

.choice_sideimage {
    object-fit: cover;
}


@media screen and (max-width: 991px) {
    .faq-section.one .faq-inner {
        margin-top: 50px !important;
        z-index: 9;
        position: relative;
    }
}

@media screen and (max-width: 1050px) {
    .container-card-casino_choice {
        height: 450px;
    }
}

@media screen and (max-width: 850px) {
    .container-card-casino_choice {
        height: 400px;
    }
}

@media screen and (max-width: 710px) {
    .container-card-casino_choice {
        height: 450px;
    }
}

@media screen and (max-width: 550px) {
    .container-card-casino_choice {
        height: 400px;
    }
}