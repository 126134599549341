.NavBottom_main_div {
    width: 100%;
    height: 80px;
    background: rgba(179, 175, 175, 0.21);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(100px);
    position: fixed;
    bottom: 0;
    z-index: 99999999999;
    cursor: pointer;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: none;
}


.NavBottom_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.NavBottom_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.icon_bottom {
    font-size: 35px;
    padding: 15px 25px;
}

.NavBottom_content img {
    width: 35px;
    margin: 17.35px 24px;
}

@media screen and (max-width: 500px) {
    .NavBottom_main_div {
        display: flex !important;
    }

    .scroll-to-top {
        background-color: white;
        right: 20px !important;
        bottom: 90px !important;
        border-radius: 7px;
    }
}