.wrapper-process {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-process {
    width: 100%;
    height: 400px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
}

.card-process {
    width: 100px;
    border-radius: .75rem;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    border-radius: 2rem;
    margin: 0 10px;
    display: flex;
    align-items: flex-end;
    transition: .6s cubic-bezier(.28, -0.03, 0, .99);
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.8);
}

.card-process>.row {
    color: white;
    display: flex;
    flex-wrap: nowrap;
}

.card-process>.row>.icon-process {
    background: #223;
    color: white;
    border-radius: 50%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    position: relative;
    left: 10px;
}

.card-process>.row>.description-process {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    height: 80px;
    width: 520px;
    opacity: 0;
    transform: translateY(30px);
    transition-delay: .3s;
    transition: all .3s ease;
}

.description-process p {
    color: #b0b0ba;
    padding-top: 5px;
}

.description-process h4 {
    text-transform: uppercase;
}

.input-process {
    display: none;
}

.input-process:checked+label {
    width: 700px;
}

.input-process:checked+label .description-process {
    opacity: 1 !important;
    transform: translateY(0) !important;
}

.card-process[for="c1"] {
    background-image: url('../../images/2151141658.jpg');
}

.card-process[for="c2"] {
    background-image: url('../../images/2151141658.jpg');
}

.card-process[for="c3"] {
    background-image: url('../../images/2151141658.jpg');
}

.card-process[for="c4"] {
    background-image: url('../../images/2151141658.jpg');
}

.card-process[for="c5"] {
    background-image: url('../../images/2151141658.jpg');
}

.card-process[for="c6"] {
    background-image: url('../../images/2151141658.jpg');
}


/* @media screen and (max-width: 1000px) {
    .container-process {
        width: 100%;
        height: 100%;
        flex-direction: column;
    }

    .card-process {
        width: 100%;
        border-radius: .75rem;
        background-size: cover;
        cursor: pointer;
        overflow: hidden;
        border-radius: 2rem;
        margin: 10px 0px;
        display: flex;
        align-items: flex-end;
        transition: .6s cubic-bezier(.28, -0.03, 0, .99);
        box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.8);
    }

    .input-process:checked+label {
        height: 700px;
        width: 100%;
    }
} */