.left_Language_div {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 61px;
    margin-bottom: 45px;
    justify-content: center;
}

.left_Language_div>div {
    font-family: var(--ff-heading);
    font-size: 22px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.right_Language_div {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

@keyframes Card_service-slide-in {
    from {
        opacity: 0;
        transform: translateY(150px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.Card_service {
    width: 150px;
    height: 130px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    opacity: 0;
    background: white !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: none;
    gap: 10px;
    border-radius: 5px !important;
}

.Card_service {
    animation: Card_service-slide-in 0.5s ease-out forwards;
}

.Card_service:nth-child(1) {
    animation-delay: 0.1s;
}

.Card_service:nth-child(2) {
    animation-delay: 0.2s;
}

.Card_service:nth-child(3) {
    animation-delay: 0.3s;
}

.Card_service:nth-child(4) {
    animation-delay: 0.4s;
}

.Card_service:nth-child(5) {
    animation-delay: 0.5s;
}

.Card_service:nth-child(6) {
    animation-delay: 0.6s;
}

.Card_service:nth-child(7) {
    animation-delay: 0.7s;
}

.Card_service:nth-child(8) {
    animation-delay: 0.8s;
}

.Card_service:nth-child(9) {
    animation-delay: 0.9s;
}


.Card_service::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Card_service:hover::before {
    opacity: 0;
}

.Card_service img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}

.Card_name {
    color: white;
}

.Card_name_sevice {
    color: black;
}

/* Card_service-2  */



@keyframes Card_service-slide-in-2 {
    from {
        opacity: 0;
        transform: translateY(150px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


.Card2_service {
    width: 150px;
    height: 130px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    opacity: 0;
    background: white !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: none;
    gap: 10px;
    border-radius: 5px !important;
}

.Card2_service {
    animation: Card_service-slide-in-2 0.5s ease-out forwards;
}

.Card2_service:nth-child(1) {
    animation-delay: 0.1s;
}

.Card2_service:nth-child(2) {
    animation-delay: 0.2s;
}

.Card2_service:nth-child(3) {
    animation-delay: 0.3s;
}

.Card2_service:nth-child(4) {
    animation-delay: 0.4s;
}

.Card2_service:nth-child(5) {
    animation-delay: 0.5s;
}

.Card2_service:nth-child(6) {
    animation-delay: 0.6s;
}

.Card2_service:nth-child(7) {
    animation-delay: 0.7s;
}

.Card2_service:nth-child(8) {
    animation-delay: 0.8s;
}

.Card2_service::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Card2_service:hover::before {
    opacity: 0;
}

.Card2_service img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}


/* Card3_service */

@keyframes Card_service-slide-in-3 {
    from {
        opacity: 0;
        transform: translateY(150px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.Card3_service {
    width: 150px;
    height: 130px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    opacity: 0;
    background: white !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: none;
    gap: 10px;
    border-radius: 5px !important;
}

.Card3_service {
    animation: Card_service-slide-in-3 0.5s ease-out forwards;
}

.Card3_service:nth-child(1) {
    animation-delay: 0.1s;
}

.Card3_service:nth-child(2) {
    animation-delay: 0.2s;
}

.Card3_service:nth-child(3) {
    animation-delay: 0.3s;
}

.Card3_service:nth-child(4) {
    animation-delay: 0.4s;
}

.Card3_service:nth-child(5) {
    animation-delay: 0.5s;
}

.Card3_service:nth-child(6) {
    animation-delay: 0.6s;
}

.Card3_service:nth-child(7) {
    animation-delay: 0.7s;
}

.Card3_service:nth-child(8) {
    animation-delay: 0.8s;
}

.Card3_service::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Card3_service:hover::before {
    opacity: 0;
}

.Card3_service img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}


/* Card_service-4 */

@keyframes Card_service-slide-in-4 {
    from {
        opacity: 0;
        transform: translateY(150px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.Card4_service {
    width: 150px;
    height: 130px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    opacity: 0;
    background: white !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: none;
    gap: 10px;
    border-radius: 5px !important;
}

.Card4_service {
    animation: Card_service-slide-in-4 0.5s ease-out forwards;
}

.Card4_service:nth-child(1) {
    animation-delay: 0.1s;
}

.Card4_service:nth-child(2) {
    animation-delay: 0.2s;
}

.Card4_service:nth-child(3) {
    animation-delay: 0.3s;
}

.Card4_service:nth-child(4) {
    animation-delay: 0.4s;
}

.Card4_service:nth-child(5) {
    animation-delay: 0.5s;
}

.Card4_service:nth-child(6) {
    animation-delay: 0.6s;
}

.Card4_service:nth-child(7) {
    animation-delay: 0.7s;
}

.Card4_service:nth-child(8) {
    animation-delay: 0.8s;
}

.Card4_service::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Card4_service:hover::before {
    opacity: 0;
}

.Card4_service img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}

/* Card5_service */

@keyframes Card_service-slide-in-5 {
    from {
        opacity: 0;
        transform: translateY(150px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.Card5_service {
    width: 150px;
    height: 130px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    opacity: 0;
    background: white !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: none;
    gap: 10px;
    border-radius: 5px !important;
}

.Card5_service {
    animation: Card_service-slide-in-5 0.5s ease-out forwards;
}

.Card5_service:nth-child(1) {
    animation-delay: 0.1s;
}

.Card5_service:nth-child(2) {
    animation-delay: 0.2s;
}

.Card5_service:nth-child(3) {
    animation-delay: 0.3s;
}

.Card5_service:nth-child(4) {
    animation-delay: 0.4s;
}

.Card5_service:nth-child(5) {
    animation-delay: 0.5s;
}

.Card5_service:nth-child(6) {
    animation-delay: 0.6s;
}

.Card5_service:nth-child(7) {
    animation-delay: 0.7s;
}

.Card5_service:nth-child(8) {
    animation-delay: 0.8s;
}

.Card5_service::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Card5_service:hover::before {
    opacity: 0;
}

.Card5_service img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}


/* Card6_service  */

@keyframes Card_service-slide-in-6 {
    from {
        opacity: 0;
        transform: translateY(150px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.Card6_service {
    width: 150px;
    height: 130px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    opacity: 0;
    background: white !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: none;
    gap: 10px;
    border-radius: 5px !important;
}

.Card6_service {
    animation: Card_service-slide-in-6 0.5s ease-out forwards;
}

.Card6_service:nth-child(1) {
    animation-delay: 0.1s;
}

.Card6_service:nth-child(2) {
    animation-delay: 0.2s;
}

.Card6_service:nth-child(3) {
    animation-delay: 0.3s;
}

.Card6_service:nth-child(4) {
    animation-delay: 0.4s;
}

.Card6_service:nth-child(5) {
    animation-delay: 0.5s;
}

.Card6_service:nth-child(6) {
    animation-delay: 0.6s;
}

.Card6_service:nth-child(7) {
    animation-delay: 0.7s;
}

.Card6_service:nth-child(8) {
    animation-delay: 0.8s;
}

.Card6_service:nth-child(9) {
    animation-delay: 0.9s;
}

.Card6_service:nth-child(10) {
    animation-delay: 1s;
}

.Card6_service:nth-child(11) {
    animation-delay: 1.1s;
}

.Card6_service::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Card6_service:hover::before {
    opacity: 0;
}

.Card6_service img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}


@media screen and (max-width:1000px) {
    .right_Language_div {
        width: 100%;
        gap: 20px;
    }

    .left_Language_div {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
    }

    .Card_service,
    .Card2_service,
    .Card3_service,
    .Card4_service,
    .Card5_service,
    .Card6_service {
        width: 150px;
        height: 130px;
    }
}

@media screen and (max-width:770px) {

    .right_Language_div {
        width: 100%;
        margin-top: 30px;
        gap: 20px;
        justify-content: center;
    }

    .left_Language_div {
        justify-content: center;
    }

}