@use "../utils" as *;

/*----------------------------------------*/
/* 15. About
/*----------------------------------------*/

//about home 1

.about-section {
  &.one {
    background: var(--common-body-1);
    padding: 100px 0;
    position: relative;
    z-index: 9;
    @media #{$sm, $xs, $xss} {
      padding: 40px 0 !important;
    }

    .left-wrapper {
      z-index: 9;
      margin-right: 25px;
      position: relative;
      @media #{$md, $sm, $xs, $xss} {
        margin-right: 0;
      }

      .img-file {
        position: relative;
        width: 100%;
        height: 530px;
        border-radius: 8px;
        overflow: hidden;
        @media #{$xs, $xss} {
          height: 430px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .shape-overlay {
          background: var(--common-body-1);
          position: absolute;
          bottom: 50px;
          left: 50%;
          padding: 30px 15px;
          border-radius: 5px;
          transform: translateX(-50%);
          text-align: center;
          width: 300px;
          height: auto;
          img {
            width: 80px;
          }
          h3 {
            text-align: center;
            font-size: 22px;
            font-weight: var(--fw-600);
            line-height: normal;
            margin-top: 20px;
          }
          .video-intro {
            margin-top: 20px;
            cursor: pointer;
            a {
              display: flex;
              gap: 10px;
              justify-content: center;
              align-items: center;
              .icon {
                width: 40px;
                height: 40px;
                border: 1px solid #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                -webkit-animation: pulse 2s infinite;
                animation: pulse 2s infinite;
                i {
                  font-size: 20px;
                }
              }
              .text {
                font-family: var(--ff-heading);
                font-size: 16px;
                font-weight: var(--fw-600);
                line-height: normal;
                margin-bottom: 0;
              }
            }
          }
        }
        .shape-7 {
          left: -30px;
          bottom: -5%;
          z-index: -1;
          img {
            width: 68px;
          }
        }
      }
    }
    .right-wrapper {
      position: relative;
      z-index: 9;
      @media #{$md, $sm, $xs, $xss} {
        margin-top: 80px;
      }

      .inner-list {
        margin-top: 20px;
        ul {
          li {
            display: flex;
            gap: 14px;
            align-items: flex-start;
            margin-bottom: 15px;
            .img-file {
              margin-top: 8px;
            }
            h4 {
              font-size: 18px;
              line-height: 28px;
              font-weight: var(--fw-600);
              margin: 0;
            }
            p {
              margin-top: 5px;
              margin-bottom: 0;
            }
          }
        }
      }
      .inner-btn {
        margin-top: 25px;
      }
    }
    .shape-img {
      .shape-1 {
        right: 15px;
        top: 80%;
        z-index: -1;

        img {
          width: 80px;
        }
        @media #{$md, $sm, $xs, $xss} {
          bottom: 3%;
          top: auto;
        }
      }
      .shape-2 {
        left: 15px;
        top: 50%;
        z-index: -1;
        -webkit-animation: slide-top 10s linear 0.3s infinite alternate-reverse
          both;
        animation: slide-top 10s linear 0.3s infinite alternate-reverse both;
        img {
          width: 40px;
        }
        @media #{$md, $sm, $xs, $xss} {
          display: none;
        }
      }
      .shape-3 {
        right: 0;
        top: 0;
        z-index: -1;
        img {
          width: 180px;
        }
      }
      .shape-4 {
        right: 25%;
        top: 9%;
        z-index: -1;
        animation: animationFramesOne 35s linear infinite;
        -webkit-animation: animationFramesOne 35s linear infinite;
        img {
          width: 30px;
        }
        @media #{$md, $sm, $xs, $xss} {
          display: none;
        }
      }
      .shape-5 {
        left: 0%;
        top: 10%;
        z-index: -1;
        animation: animationFramesOne 35s linear infinite reverse;
        -webkit-animation: animationFramesOne 35s linear infinite reverse;
        img {
          width: 30px;
        }
      }
      .shape-6 {
        left: 59%;
        top: 8%;
        z-index: -1;
        -webkit-animation: rotate-in-center 6s linear 0s infinite reverse
          backwards;
        animation: rotate-in-center 6s linear 0s infinite reverse backwards;
        img {
          width: 25px;
        }
        @media #{$md, $sm, $xs, $xss} {
          left: 64%;
          top: 4%;
        }
        @media #{$sm, $xs, $xss} {
          left: 57%;
        }
        @media #{$xs, $xss} {
          left: 35%;
        }
      }
    }
  }
  &.two {
    background: var(--common-body-1);
    padding: 120px 0;
    position: relative;
    z-index: 9;
    overflow: hidden;

    .inner-list {
      position: relative;
      z-index: 9;
      margin-top: 20px;
      ul {
        li {
          font-size: 16px;
          line-height: 26px;
          position: relative;
          padding-left: 20px;
          margin-bottom: 15px;
          &::after {
            position: absolute;
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            content: "\f324";
            left: 0;
            top: 0;
            font-size: 15px;
            color: var(--theme-1);
          }
        }
      }
      .inner-btn {
        margin-top: 30px;
        justify-content: flex-start;
      }
    }
    .img-file {
      padding-left: 20px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      @media #{$md, $sm, $xs, $xss} {
        padding-left: 0;
        justify-content: center;
      }
    }
    .shape-img {
      .shape-1 {
        left: -4px;
        top: 40px;
        width: 85px;
        z-index: -1;
      }
      .shape-2 {
        right: 40px;
        top: -3px;
        width: 85px;
        z-index: -1;
      }
      .shape-3 {
        right: 230px;
        bottom: 80px;
        width: 120px;
        z-index: -1;
        animation: trigger 6s linear infinite;
        @media #{$md, $sm, $xs, $xss} {
          right: 30px;
        }
      }
      .shape-4 {
        right: 570px;
        bottom: 24px;
        width: 30px;
        z-index: -1;
        animation: trigger 6s linear 0.3s infinite reverse;
        @media #{$lg} {
          right: 349px;
          bottom: 24px;
        }
        @media #{$md, $sm, $xs, $xss} {
          left: 240px;
          right: auto;
        }
      }
      .shape-5 {
        right: 570px;
        top: 24px;
        width: 34px;
        z-index: -1;
        animation: animationFramesOne 30s linear infinite reverse backwards;
        -webkit-animation: animationFramesOne 30s linear infinite reverse
          backwards;
      }
      .shape-6 {
        right: 342px;
        top: 67px;
        width: 20px;
        z-index: -1;
        animation: trigger 6s linear infinite reverse;
      }
      .shape-7 {
        left: 340px;
        top: 48px;
        width: 180px;
        z-index: -1;
        @media #{$sm, $xs, $xss} {
          left: 225px;
          width: 150px;
        }
        @media #{$xs, $xss} {
          left: 145px;
        }
      }
      .shape-8 {
        left: -10px;
        bottom: -14px;
        width: 143px;
        z-index: -1;
        transform: rotate(270deg);
      }
      .shape-9 {
        left: 419px;
        bottom: 30px;
        width: 80px;
        z-index: -1;
        @media #{$sm, $xs, $xss} {
          display: none;
        }
      }
      .shape-10 {
        left: 548px;
        top: 30px;
        width: 25px;
        z-index: -1;
        animation: animationFramesOne 50s linear infinite;
        -webkit-animation: animationFramesOne 50s linear infinite;
      }
      .shape-11 {
        left: 775px;
        bottom: 130px;
        width: 50px;
        z-index: -1;
        animation: rotate-in-center 12s linear 0s infinite reverse backwards;
      }
    }
  }
  &.three {
    background: var(--common-white);
    padding: 100px 0;
    position: relative;
    z-index: 9;
    overflow: hidden;
    @media #{$sm, $xs, $xss} {
      padding: 40px 0 !important;
    }
    .left-wrapper {
      .img-file {
        @media #{$md, $sm, $xs, $xss} {
          text-align: center;
        }
      }
    }
    .right-wrapper {
      @media #{$md, $sm, $xs, $xss} {
        margin-top: 30px;
        padding-left: 0;
      }
      .inner-list {
        position: relative;
        z-index: 9;
        margin-top: 20px;
        @media #{$md, $sm, $xs, $xss} {
          margin-top: 30px;
        }
        ul {
          li {
            display: flex;
            gap: 16px;
            align-items: flex-start;
            margin-bottom: 10px;
            .img-file {
              width: 170px;
              height: auto;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: fill;
              }
            }
            h4 {
              color: var(--text-h);
              font-size: 22px;
              font-weight: var(--fw-800);
              margin: 0;
            }
            p {
              margin-bottom: 0;
              color: var(--text-p-1);
            }
          }
        }
      }
    }

    .shape-img {
      .shape-1 {
        right: -349px;
        bottom: -190px;
        width: 654px;
        z-index: -1;
        opacity: 0.7;
      }
      .shape-2 {
        left: 40px;
        top: 41px;
        width: 80px;
        z-index: -1;
        animation: trigger 6s linear infinite;
        @media #{$md, $sm, $xs, $xss} {
          left: 50%;
          top: 254px;
          width: 100px;
          z-index: 0;
          animation: none;
          transform: translateX(-50%);
        }
        @media #{$sm} {
          top: 210px;
        }
        @media #{$xs, $xss} {
          top: 155px;
        }
      }
    }
  }
}
