@use "../utils" as *;

/*----------------------------------------*/
/* 29. 404
/*----------------------------------------*/

// Error Section

.error-section {
  background: var(--common-body-1);
  padding: 120px 0;
  position: relative;
  width: 100%;
  min-height: 100vh;
  z-index: 9;
  display: flex;
  align-items: center;
  .wrapper {
    text-align: center;

    .img-file {
      width: 400px;
      height: 400px;
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text-file {
      margin-top: 20px;
      h4 {
        font-size: 26px;
        font-weight: 800;
      }
      p {
        font-size: 15px;
      }
    }
  }
}
