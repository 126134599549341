@use "../utils" as *;

/*----------------------------------------*/
/* 28. Newsletter
/*----------------------------------------*/

.news-letter {
  &.one {
    padding: 60px 0;
    background: var(--common-body-2);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 9;
    position: relative;
    overflow: hidden;
    .left-wrapper {
      h5 {
        font-size: 16px;
        color: var(--theme-1);
        line-height: 1.2;
      }
      h2 {
        font-size: 30px;
        text-transform: capitalize;
        line-height: 1.2;
        margin: 0;
        @media #{$lg, $md, $sm, $xs, $xss} {
          font-size: 22px;
        }
      }
    }
    .right-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      @media #{$md, $sm, $xs, $xss} {
        margin-top: 10px;
        justify-content: flex-start;
      }
      .input-box {
        position: relative;
        margin-top: 24px;
        @media #{$lg, $md, $sm, $xs, $xss} {
          margin-top: 0;
        }

        input {
          width: 390px;
          height: 45px;
          border-radius: 5px;
          border: none;
          padding: 2px 15px;
          color: var(--text-p-1);
          &::-webkit-input-placeholder {
            color: var(--text-p-1);
          }
          @media #{$xs, $xss} {
            width: 100%;
          }
        }

        .submit {
          width: auto;
          display: inline-block;
          background: var(--theme-1);
          padding: 10px 15px;
          border-radius: 5px;
          margin-left: 6px;
          &:hover {
            animation: pulse-small 1s;
          }
          @media #{$lg, $md, $sm, $xs, $xss} {
            margin-left: 0;
            margin-top: 12px;
          }
        }
      }
    }
    .shape-image {
      .shape-1 {
        left: 18px;
        z-index: -1;
        width: 30px;
        top: 60px;
        @media #{$lg, $md, $sm, $xs, $xss} {
          width: 30px;
          top: 22px;
        }
      }
      .shape-2 {
        right: 26%;
        z-index: -1;
        width: 100px;
        bottom: 15px;
        opacity: 0.6;
      }
      .shape-3 {
        right: 26%;
        z-index: -1;
        width: 100px;
        bottom: 15px;
      }
    }
  }
  &.two {
    padding: 60px 0;
    background: var(--common-body-2);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 9;
    position: relative;
    overflow: hidden;
    .text-wrapper {
      text-align: center;
      @media #{$xs, $xss} {
        text-align: start;
      }

      h5 {
        font-size: 16px;
        color: var(--theme-1);
        line-height: 1.2;
      }
      h2 {
        font-size: 30px;
        text-transform: capitalize;
        line-height: 1.2;
        margin: 0;

        @media #{$xs, $xss} {
          font-size: 22px;
        }
      }
    }
    .input-wrapper {
      display: flex;
      justify-content: center;

      @media #{$xs, $xss} {
        justify-content: flex-start;
      }
      .input-box {
        position: relative;
        margin-top: 24px;

        input {
          width: 390px;
          height: 45px;
          border-radius: 5px;
          border: none;
          padding: 2px 15px;
          color: var(--text-p-1);
          &::-webkit-input-placeholder {
            color: var(--text-p-1);
          }
          @media #{$xs, $xss} {
            width: 100%;
          }
        }

        .submit {
          width: auto;
          display: inline-block;
          background: var(--theme-1);
          padding: 10px 15px;
          border-radius: 5px;
          margin-left: 6px;
          @media #{$xs, $xss} {
            margin-left: 0;
            margin-top: 12px;
          }
          &:hover {
            animation: pulse-small 1s;
          }
        }
      }
    }
    .shape-image {
      .shape-1 {
        left: -3px;
        z-index: -1;
        width: 200px;
        bottom: -16px;
      }
      .shape-2 {
        right: 15%;
        z-index: -1;
        width: 75px;
        bottom: 15px;
        @media #{$md, $sm, $xs, $xss} {
          right: 0%;
        }
      }
    }
  }
}
