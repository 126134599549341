/* experience */

.raidenarcviz_h2 {
    color: white;
    text-align: center;
    margin-bottom: 40px !important;
}

.Raidenarcviz_main_div {
    background: #00151E !important;
}

.arcviz_who_heading {
    margin-bottom: 8px !important;
}

.Raidenarcviz p {
    color: white;
    text-align: center;
    font-size: 18px !important;
}

/* what we do  */

.inner-des ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 25px;
}

.inner-des li {
    font-size: 16px;
    list-style-type: square;
}


/* cards- 2 */

.raidenarcviz_card_h2 {
    color: black;
    text-align: center;
    margin-bottom: 20px !important;
}

.raidenarcviz_card_p {
    margin-bottom: 80px;
    text-align: center;
}

.arcviz_card_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 100px;
}

.arcviz_card {
    width: 48%;
    background: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 40px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: relative;
    padding-top: 60px;
    transition: box-shadow 0.3s ease;
}

.arcviz_card:hover {
    box-shadow: rgba(0, 40, 67, 1) 5px 5px;
}

.arcviz_card div {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    color: black;
}

.arcviz_card p {
    color: black;
}

.arcviz_round_img_div {
    position: absolute;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: linear-gradient(136.76deg, #F40076 0%, #DF98FA 100%);
    top: 0px;
    transform: translate(-50%, -50%);
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 11px solid rgba(245, 245, 245, 1)
}

.arcviz_round_img_div img {
    width: 40px;
    height: 40px;
}


@media screen and (max-width:800px) {
    .arcviz_card div {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
        color: black;
    }

    .arcviz_card {
        width: 49%;
    }
}


@media screen and (max-width:650px) {

    .arcviz_card {
        width: 100%;
    }
}



/* benifit  */

.arcviz_benifit_div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 50px;
    padding: 0;
}

.arcviz_benifit {
    width: 31%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.arcviz_benifit img {
    width: 50px;
    height: 50px;
}

.arcviz_benifit div {
    color: white;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-top: 25px;
}

.arcviz_benifit p {
    color: #CFCFD2;
    text-align: center;
    margin-top: 10px;
}


@media screen and (max-width:1000px) {

    .arcviz_benifit {
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

@media screen and (max-width:600px) {

    .arcviz_benifit {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

/* use case  */

.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 0) !important;
    right: auto;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 0) !important;
    left: auto;
}

.swiper-button-next,
.swiper-button-prev {
    top: var(--swiper-navigation-top-offset, 38.5%) !important;
    background: white;
    padding: 30px 30px;

}