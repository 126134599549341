@use "../utils" as *;

/*----------------------------------------*/
/* 20. Widget
/*----------------------------------------*/

.widget-area {
  @media #{$md, $sm, $xs, $xss} {
    margin-top: 60px;
  }

  .widget {
    &.widget-search {
      .search {
        background: var(--common-white);
        padding: 15px 20px;
        border-radius: 5px;
        .title {
          h2 {
            font-size: 20px;
            line-height: 40px;
            font-weight: 800;
            color: var(--text-h);
          }
        }
        .search-wrap {
          .search-form {
            position: relative;
            overflow: hidden;
            input {
              background-color: transparent;
              height: 50px;
              padding: 6px 15px;
              width: 100%;
              display: block;
              outline: 0;
              @include transition(0.3s);
              border: 1px solid #eee;
              border-radius: 5px;
              font-size: 16px;
              overflow: hidden;
            }
            button {
              position: absolute;
              right: 0px;
              top: 0px;
              width: 50px;
              background: var(--theme-1);
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0px 5px 5px 0px;
              @include transition(0.2s);
              &:hover {
                background: var(--common-body-1);
              }
            }
          }
        }
      }
    }
    &.widget-post {
      .post {
        background: var(--common-white);
        padding: 15px 20px;
        border-radius: 5px;
        .title {
          h2 {
            font-size: 20px;
            line-height: 40px;
            font-weight: 800;
            color: var(--text-h);
            margin: 0;
          }
        }
        .post-item {
          display: flex;
          align-items: center;
          gap: 15px;
          border-bottom: 1px solid #ddd;
          padding: 10px 0;
          .img-file {
            width: 80px;
            height: 80px;
            overflow: hidden;
            img {
              width: 380px;
              height: 270px;
              object-fit: cover;
            }
          }
          .info {
            time {
              color: var(--text-p-1);
              margin-bottom: 4px;
              display: inline-block;
            }
            .title {
              font-size: 14px;
              font-weight: 700;
              line-height: 1.2;

              a {
                color: var(--text-h);
                transition: all 0.2s linear;
                &:hover {
                  color: var(--theme-1);
                }
              }
            }
          }
          &:last-child {
            border: none;
          }
        }
      }
    }
    &.widget-follow {
      margin-top: 30px;
      .follow {
        background: var(--common-white);
        padding: 15px 20px;
        border-radius: 5px;
        .title {
          h2 {
            font-size: 20px;
            line-height: 40px;
            font-weight: 800;
            color: var(--text-h);
            margin: 0;
          }
        }
        .social {
          margin-top: 10px;
          ul {
            display: flex;
            gap: 10px;
            li {
              a {
                display: block;
                width: 40px;
                height: 40px;
                border: 1px solid var(--theme-1);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                transition: all 0.2s linear;
                i {
                  font-size: 18px;
                  color: var(--theme-1);
                  transition: all 0.2s linear;
                }
                &:hover {
                  background: var(--theme-1);
                  i {
                    color: var(--common-white);
                  }
                }
              }
            }
          }
        }
      }
    }
    &.widget-categories {
      margin-top: 30px;
      .categories {
        background: var(--common-white);
        padding: 15px 20px;
        border-radius: 5px;
        .title {
          h2 {
            font-size: 20px;
            line-height: 40px;
            font-weight: 800;
            color: var(--text-h);
            margin: 0;
          }
        }
        .list-inner {
          margin-top: 10px;
          ul {
            li {
              padding-bottom: 4px;
              transition: all 0.2s linear;
              a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 0;
                font-size: 15px;
                font-weight: 600;
                color: var(--text-h);
                border-bottom: 1px solid #ddd;
                @include transition(0.2s);
                &:hover {
                  color: var(--theme-1);
                }
              }
              &:last-child {
                a {
                  border: none;
                }
              }
            }
          }
        }
      }
    }
    &.widget-tags {
      margin-top: 30px;
      .tags {
        background: var(--common-white);
        padding: 15px 20px;
        border-radius: 5px;
        .title {
          h2 {
            font-size: 20px;
            line-height: 40px;
            font-weight: 800;
            color: var(--text-h);
            margin: 0;
          }
        }
        .list-grid {
          a {
            display: inline-block;
            color: var(--text-p-1);
            font-weight: 400;
            font-size: 15px;
            padding: 6px 10px;
            border: 1px solid #ddd;
            margin-top: 10px;
            margin-right: 10px;
            border-radius: 5px;
            transition: all 0.2s linear;
            &:hover {
              background: var(--theme-1);
              color: var(--common-white);
              border: 1px solid var(--theme-1);
            }
          }
        }
      }
    }
  }
}

.search {
  background: var(--common-white);
  padding: 30px 0px;
  border-radius: 5px;
      @media #{$sm ,$xs, $xss} {
      padding: 10px 0;
    }
  .title {
    h2 {
      font-size: 20px;
      line-height: 40px;
      font-weight: 800;
      color: var(--text-h);
    }
  }
  .search-wrap {
    .search-form {
      position: relative;
      overflow: hidden;
      input {
        background-color: transparent;
        height: 50px;
        padding: 6px 15px;
        width: 100%;
        display: block;
        outline: 0;
        @include transition(0.3s);
        border: 1px solid #eee;
        border-radius: 5px;
        font-size: 16px;
        overflow: hidden;
      }
      button {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 50px;
        background: var(--theme-1);
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 5px 5px 0px;
        @include transition(0.2s);
        &:hover {
          background: var(--common-body-1);
        }
      }
    }
  }
}
.casestudy_filter_container {
  display: flex;
  align-items: center;
  @media #{$md, $sm, $xs, $xss} {
    row-gap: 10px;
  }
}
.casestudy_filter {
  display: flex;
  align-items: center;
  height: 100%;
  @media #{$md, $sm, $xs, $xss} {
    margin-bottom: 40px;
    row-gap: 10px;
  }
  .filter {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;
    gap: 20px;
    @media #{$md, $sm, $xs, $xss} {
      justify-content: space-around;
    }
    p {
      color: black;
      margin: 0;
      width: fit-content;
      width: 100px;
    }
    select {
      height: 50px;
      width: 250px;
      outline: none;
      border: 1px solid #eee;
      border-radius: 5px;
      padding: 0 10px 0 10px;
      @media #{$md, $sm, $xs, $xss} {
        width: 100%;
      }
    }
  }
}
