@media screen and (max-width: 1140px) {
    .key_features_card {
        width: 48% !important;
    }
}

@media screen and (max-width: 730px) {
    .key_features_card {
        width: 100% !important;
        height: 100% !important;
    }
}

.blockgame_benifit_main_div {
    width: 100%;
    height: 100%;
    margin-top: 60px;
}

@media screen and (max-width: 768px) {
    .blockgame_benifit_main_div {
        margin-top: 30px;
    }

}

.blockgame_benifit_div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 25px;

}

.blockgame_benifit_card {
    padding: 15px;
    width: 32%;
    background: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
}

.blockgame_benifit_card img {
    width: 100%;
    height: 50%;
    object-fit: cover;
    margin-bottom: 15px;
}

.blockgame_benifit_card div {
    color: black;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 700;
}

.blockgame_benifit_card p {
    color: black;
}


@media screen and (max-width: 950px) {

    .blockgame_benifit_card {
        padding: 15px;
        width: 48%;
        background: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 8px;
    }

}

@media screen and (max-width: 650px) {

    .blockgame_benifit_card {
        padding: 15px;
        width: 100%;
        height: 100%;
        background: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 8px;
    }

}

/* featurese  */

.blockchain_game_card_maindiv {
    width: 100%;
    margin-top: 60px !important;
}

@media screen and (max-width: 768px) {
    .blockchain_game_card_maindiv {
        margin-top: 30px !important;
    }
}

.industry_wide_card_blockchain {
    position: relative;
    background-repeat: no-repeat;
    object-fit: cover;
    height: 339px;
    width: 32% !important;
    padding: 10px;
    padding-top: 220px;
    display: flex;
    flex-direction: column;
    z-index: 20;
    border-radius: 6px;
    align-items: center;
    overflow: hidden;
    justify-content: center;
}



.classy_simx_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 11;
}

.industry_wide_card_blockchain img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
    height: 100%;
    transition: ease-in-out 0.5s;
}

.industry_wide_card_blockchain h6 {
    z-index: 20;
    font-size: 18px;
    text-align: center;
}

.industry_wide_card_blockchain p {
    z-index: 20;
    font-size: 18px;
    text-align: center;
}


@media screen and (max-width: 1200px) {

    .industry_wide_card_blockchain {
        position: relative;
        background-repeat: no-repeat;
        object-fit: cover;
        height: 339px;
        width: 32% !important;
        padding: 10px;
        padding-top: 220px;
    }

    .indutry_container_padding {
        padding: 0 30px !important;
        justify-content: center !important;
        gap: 15px !important;
    }

}

@media screen and (max-width: 900px) {

    .industry_wide_card_blockchain {
        position: relative;
        background-repeat: no-repeat;
        object-fit: cover;
        height: 339px;
        width: 49% !important;
        padding: 10px;
        padding-top: 220px;
    }

    .indutry_container_padding {
        padding: 0 30px !important;
        justify-content: space-between !important;
        gap: 0 !important;
        row-gap: 15px !important;
    }

}

@media screen and (max-width: 900px) {

    .industry_wide_card_blockchain {
        position: relative;
        background-repeat: no-repeat;
        object-fit: cover;
        height: 339px;
        width: 49% !important;
        padding: 10px;
        padding-top: 220px;
    }

    .indutry_container_padding {
        padding: 0 30px !important;
        justify-content: space-between !important;
        gap: 0 !important;
        row-gap: 15px !important;
    }

}

@media screen and (max-width: 768px) {


    .indutry_container_padding {
        padding: 0 15px !important;
        justify-content: space-between !important;
        gap: 0 !important;
        row-gap: 15px !important;
    }

}

@media screen and (max-width: 600px) {


    .industry_wide_card_blockchain {
        position: relative;
        background-repeat: no-repeat;
        object-fit: cover;
        height: 339px;
        width: 100% !important;
        padding: 10px;
        padding-top: 220px;
    }

}